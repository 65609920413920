import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {Toast} from "../style/StyledToastContainer.tsx";
import '../contexts/AuthContext';
import './Header.css';
import axiosInstance from "../axiosInstance";
import ProfileEditModal from "./modal/ProfileEditModal";
import brandHeader from "../style/icon/png/brandHeader.png";
import "../style/colors.css";

const Header = () => {
    const { isLoggedIn, user, logout } = useAuth();

    const [isNotiOpen, setIsNotiOpen] = useState(false);
    const [hasNewNotification, setHasNewNotification] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [isProfileEditOpen, setIsProfileEditOpen] = useState(false);
    const [nickname, setNickname] = useState("");
    const [readNotiIds, setReadNotiIds] = useState([]);

    const [notiList, setNotiList] = useState([]);

    const menuRef = useRef(null);
    const notiButtonRef = useRef(null);
    const profileButtonRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();

    const handleClickProduct = () => {
        if (location.pathname === '/') {
            const productSection = document.getElementById('product');
            if (productSection) {
                productSection.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            navigate('/', { state: { scrollToProduct: true } });
        }
    };

    const handleClickLogin = () => {
        navigate("/login", { state: { from: location }, replace: true});
    };

    const toggleNoti = () => {
        setIsNotiOpen(prev => !prev);
        setIsProfileOpen(false);
    };

    const toggleProfile = () => {
        setIsProfileOpen(prev => !prev);
        setIsNotiOpen(false);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target) &&
            notiButtonRef.current && !notiButtonRef.current.contains(event.target) &&
            profileButtonRef.current && !profileButtonRef.current.contains(event.target)
        ) {
            setIsNotiOpen(false);
            setIsProfileOpen(false);
        }
    };

    const handleClickEdit = () => {
        setIsProfileEditOpen(true);
        setIsProfileOpen(false);
    }

    const handleCloseModal = () => {
        setIsProfileEditOpen(false);
    }

    const handleLogout = async () => {
        try {
            await logout();
            toggleProfile();
        } catch (error) {
            Toast.error(error.message);
        }
    };

    const timeAgo = (date) => {
        const now = new Date();
        const seconds = Math.floor((now - new Date(date)) / 1000);
        let interval = Math.floor(seconds / 31536000);

        if (interval >= 1) {
            return `${interval}년 전`;
        }
        interval = Math.floor(seconds / 2592000);
        if (interval >= 1) {
            return `${interval}개월 전`;
        }
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
            return `${interval}일 전`;
        }
        interval = Math.floor(seconds / 3600);
        if (interval >= 1) {
            return `${interval}시간 전`;
        }
        interval = Math.floor(seconds / 60);
        if (interval >= 1) {
            return `${interval}분 전`;
        }
        return "방금 전";
    };

    useEffect(() => {
        const savedReadNotiIds = JSON.parse(localStorage.getItem('readNotiIds')) || [];
        setReadNotiIds(savedReadNotiIds);
    }, []);

    const fetchNotifications = async () => {
        try {
            const response = await axiosInstance.get('/notification');
            const sortedNotiList = response.data.notificationList
                .filter(noti => noti.userId == user.userId) // userId와 일치하는 알림만 받아옴
                .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // 최신 알림이 위로

            const notiListWithProfiles = await Promise.all(sortedNotiList.map(async (noti) => {
                try {
                    const userResponse = await axiosInstance.get(`/user/${noti.creatorId}/profile`);
                    const nickname = userResponse.data.nickname;

                    let content = '';
                    switch (noti.type) {
                        case 'EVENT':
                            content = '새로운 이벤트가 주최되었어요, 게스트를 초대해보세요';
                            break;
                        case 'NEW_FEED':
                            content = `${nickname}님이 새 포스트를 작성했어요!`;
                            break;
                        case 'ATTEND':
                            content = `${nickname}님이 이벤트에 참여했어요`;
                            break;
                        case 'COMMENT':
                        case 'LIKE':
                        case 'INVITE':
                        default:
                    }

                    return {
                        ...noti,
                        isRead: readNotiIds.includes(noti.id),
                        content,
                        user: {
                            profileImageUrl: userResponse.data.profileImageUrl,
                            nickname
                        },
                        timeAgo: timeAgo(noti.createdAt)
                    };
                } catch (error) {
                    console.error(error.message);
                }
            }));

            setNotiList(notiListWithProfiles);

            // 읽지 않은 알림이 있는지 확인
            const hasNewNoti = notiListWithProfiles.some(noti => !noti.isRead);
            setHasNewNotification(hasNewNoti);
        } catch (error) {
            Toast.error(error.message);
        }
    };

    const handleNotiClick = (noti) => {
        const savedReadNotiIds = JSON.parse(localStorage.getItem('readNotiIds')) || [];
        if (!savedReadNotiIds.includes(noti.id)) {
            const updatedReadNotiIds = [...savedReadNotiIds, noti.id];
            localStorage.setItem('readNotiIds', JSON.stringify(updatedReadNotiIds));
            setReadNotiIds(updatedReadNotiIds); // 상태를 즉시 업데이트
        }

        if (noti.eventId) {
            navigate(`/events/${noti.eventId}`);
        }
    };

    useEffect(() => {
        if (isLoggedIn || isNotiOpen) {
            fetchNotifications();
        }
    }, [isLoggedIn, isNotiOpen]);

    useEffect(() => {
        if (isLoggedIn) {
            fetchNotifications();
        }
    }, []);

    useEffect(() => {
        if (isNotiOpen || isProfileOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isNotiOpen, isProfileOpen]);

    useEffect(() => {
        const fetchNickname = async () => {
            if (isLoggedIn) {
                try {
                    const response = await axiosInstance.get(`/user/${user.userId}/profile`);
                    setNickname(response.data.nickname);
                } catch (error) {
                    console.error(error.message);
                }
            }
        };

        fetchNickname();
    }, [user]);

    // 로그인 경로일 때 헤더 숨김
    if (location.pathname === '/login') {
        return null;
    }

    return (
        <>
            {isProfileEditOpen && (
                <ProfileEditModal userId={user.userId} onClose={handleCloseModal} />
            )}
            <header className="header">
                <div className="logo">
                    <Link to="/">
                        <img src={brandHeader} width="74" height="24" />
                    </Link>
                </div>
                <nav className="nav">
                    {isLoggedIn ? (
                            <ul className="login-menu">
                                <li>
                                    <svg className="noti-button hover-icon"
                                         ref={notiButtonRef}
                                         onClick={toggleNoti}
                                         width="30px"
                                         height="30px"
                                         viewBox="0 0 30 30">
                                        <>
                                            <path
                                                d="M14.5 26C13.5375 26 12.7135 25.7063 12.0281 25.1188C11.3427 24.5313 11 23.825 11 23H18C18 23.825 17.6573 24.5313 16.9719 25.1188C16.2865 25.7063 15.4625 26 14.5 26Z"
                                                fill="currentColor"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                  d="M22.3929 19.7021H23.3308C24.0557 19.7021 24.6433 20.2897 24.6433 21.0146C24.6433 21.7395 24.0557 22.3272 23.3308 22.3272H5.70479C4.97987 22.3272 4.39221 21.7395 4.39221 21.0146C4.39221 20.2897 4.97987 19.7021 5.70479 19.7021H6.92326V19.7008V13.4005C6.92326 11.9479 7.45063 10.6572 8.50538 9.52838C9.56013 8.39956 10.9313 7.66015 12.6189 7.31013V6.57509C12.6189 6.13756 12.8035 5.76567 13.1726 5.4594C13.5418 5.15313 13.9901 5 14.5174 5C15.0448 5 15.4931 5.15313 15.8622 5.4594C16.2314 5.76567 16.416 6.13756 16.416 6.57509V7.31013C18.1036 7.66015 19.4747 8.39956 20.5295 9.52838C21.5842 10.6572 22.1116 11.9479 22.1116 13.4005V19.7008H22.3929V19.7021ZM19.5802 19.7008H9.45465V13.4005C9.45465 12.2454 9.95038 11.2566 10.9418 10.4341C11.9333 9.61151 13.1252 9.20023 14.5174 9.20023C15.9097 9.20023 17.1016 9.61151 18.093 10.4341C19.0845 11.2566 19.5802 12.2454 19.5802 13.4005V19.7008Z"
                                                  fill="currentColor"/>
                                            {hasNewNotification && <circle cx="25.5" cy="4.5" r="2.5" fill="#2255FF"/>}
                                        </>
                                    </svg>
                                    {isNotiOpen && (
                                        <div className="noti-menu" ref={menuRef}>
                                            {notiList.length > 0 ? (
                                                notiList.map((noti, index) => (
                                                    <div key={index} className="noti-item" onClick={() => handleNotiClick(noti)}>
                                                        {noti.user && noti.user.profileImage ? (
                                                            <img
                                                                src={noti.user.profileImageUrl}
                                                                alt="프로필 이미지"
                                                                className="noti-profile-image"
                                                            />
                                                        ) : (
                                                            <div className="empty-noti-profile-image">
                                                                {noti.user.nickname.slice(0, 1)}
                                                            </div>
                                                        )}
                                                        <div style={{display: "flex", flexDirection: "column"}}>
                                                            <p className={`noti-content ${noti.isRead ? 'old' : 'new'} hover-text`}>{noti.content}</p>
                                                            <p className="noti-time">{noti.timeAgo}</p>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="no-noti">
                                                    <p>아직 알림이 없어요 🥲</p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </li>
                                <li>
                                    <svg className="profile-button hover-icon"
                                         ref={profileButtonRef}
                                         onClick={toggleProfile}
                                         width="30px"
                                         height="30px"
                                         viewBox="0 0 30 30">
                                        <path
                                            d="M5.00006 22.4998V21.6873C5.00006 21.2623 5.20006 20.8623 5.51256 20.6748C7.62506 19.4123 10.0376 18.7498 12.5001 18.7498C12.5376 18.7498 12.5626 18.7498 12.6001 18.7623C12.7251 17.8873 12.9751 17.0498 13.3376 16.2873C13.0626 16.2623 12.7876 16.2498 12.5001 16.2498C9.47506 16.2498 6.65006 17.0873 4.23756 18.5248C3.13756 19.1748 2.50006 20.3998 2.50006 21.6873V24.9998H14.0751C13.5501 24.2498 13.1376 23.3998 12.8626 22.4998H5.00006Z"
                                            fill="currentColor"/>
                                        <path
                                            d="M12.5001 14.9998C15.2626 14.9998 17.5001 12.7623 17.5001 9.99982C17.5001 7.23732 15.2626 4.99982 12.5001 4.99982C9.73756 4.99982 7.50006 7.23732 7.50006 9.99982C7.50006 12.7623 9.73756 14.9998 12.5001 14.9998ZM12.5001 7.49982C13.8751 7.49982 15.0001 8.62482 15.0001 9.99982C15.0001 11.3748 13.8751 12.4998 12.5001 12.4998C11.1251 12.4998 10.0001 11.3748 10.0001 9.99982C10.0001 8.62482 11.1251 7.49982 12.5001 7.49982Z"
                                            fill="currentColor"/>
                                        <path
                                            d="M25.9376 19.9998C25.9376 19.7248 25.9001 19.4748 25.8626 19.2123L27.2876 17.9498L26.0376 15.7873L24.2251 16.3998C23.8251 16.0623 23.3751 15.7998 22.8751 15.6123L22.5001 13.7498H20.0001L19.6251 15.6123C19.1251 15.7998 18.6751 16.0623 18.2751 16.3998L16.4626 15.7873L15.2126 17.9498L16.6376 19.2123C16.6001 19.4748 16.5626 19.7248 16.5626 19.9998C16.5626 20.2748 16.6001 20.5248 16.6376 20.7873L15.2126 22.0498L16.4626 24.2123L18.2751 23.5998C18.6751 23.9373 19.1251 24.1998 19.6251 24.3873L20.0001 26.2498H22.5001L22.8751 24.3873C23.3751 24.1998 23.8251 23.9373 24.2251 23.5998L26.0376 24.2123L27.2876 22.0498L25.8626 20.7873C25.9001 20.5248 25.9376 20.2748 25.9376 19.9998ZM21.2501 22.4998C19.8751 22.4998 18.7501 21.3748 18.7501 19.9998C18.7501 18.6248 19.8751 17.4998 21.2501 17.4998C22.6251 17.4998 23.7501 18.6248 23.7501 19.9998C23.7501 21.3748 22.6251 22.4998 21.2501 22.4998Z"
                                            fill="currentColor"/>
                                    </svg>
                                    {isProfileOpen && (
                                        <div className="profile-menu" ref={menuRef}>
                                            <div className="profile-user">
                                                <p className="username">{nickname}</p>
                                                <p className="user-id">{user.username}</p>
                                            </div>
                                            <div className="line" />
                                            <div className="profile-btn-wrapper">
                                                <button onClick={handleClickEdit} className="hover-text">프로필 수정</button>
                                                <button />
                                                {/*<button className="hover-text">커피챗 히스토리</button>*/}
                                                <button onClick={handleLogout} className="hover-text">로그아웃</button>
                                            </div>
                                        </div>
                                    )}
                                </li>
                            </ul>
                    ) : (
                        <ul className="logout-menu">
                            <li>
                                <button className="product-btn hover-text" onClick={handleClickProduct}>프로덕트</button>
                            </li>
                            <li>
                                <button className="web-header-btn hover-btn" onClick={handleClickLogin}>로그인</button>
                            </li>
                        </ul>
                    )}
                </nav>
            </header>
        </>
    );
};

export default Header;