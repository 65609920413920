import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import axiosInstance from "../axiosInstance";
import {Toast} from "../style/StyledToastContainer.tsx";
import {ERROR_MESSAGES} from "../constants/errorMessages";
import "./EventDetail.css";
import Feed from "../component/Feed";
import StatusChangeModal from "../component/modal/StatusChangeModal";
import Calendar from "react-calendar";
import LoginModal from "../component/modal/LoginModal";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {storage} from "../firebase";
import ProfileCard from "../component/ProfileCard";
import SignupModal from "../component/modal/SignupModal";
import icPeople from "../style/icon/png/icPeople.png";
import icAttending from "../style/icon/png/icAttending.png";
import icNotAttending from "../style/icon/png/icNotAttending.png";
import {Tooltip} from "../style/components";
import icEdit from "../style/icon/png/icEdit.png";
import icSettings from "../style/icon/png/icSettings.png";
import btnShareHover from "../style/icon/png/btnShare_hover.png";
import btnShare from "../style/icon/png/btnShare_default.png";
import icCalendar from "../style/icon/png/icCalendar.png";
import icLocation from "../style/icon/png/icLocation.png";
import icHost from "../style/icon/png/icHost.png";
import icMemo from "../style/icon/png/icMemo.png";

const EventDetail = () => {
    const { id } = useParams();
    const { isLoggedIn, setIsLoggedIn, user, isLoading } = useAuth();

    const [event, setEvent] = useState(null);
    const [status, setStatus] = useState("");

    const [title, setTitle] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [place, setPlace] = useState("");
    const [about, setAbout] = useState("");
    const [imageUrl, setImageUrl] = useState(null);
    const [creatorId, setCreatorId] = useState("");

    const [creatorName, setCreatorName] = useState("");
    const [creatorProfileUrl, setCreatorProfileUrl] = useState("");
    const [creatorCompany, setCreatorCompany] = useState("");
    const [creatorLink, setCreatorLink] = useState("");
    const [creatorIntroduction, setCreatorIntroduction] = useState("");

    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [currentAction, setCurrentAction] = useState("");
    const [isHostModalOpen, setIsHostModalOpen] = useState(false);

    const [showTooltip, setShowTooltip] = useState(false);

    const [isShareHovered, setIsShareHovered] = useState(false);

    const navigate = useNavigate();

    const formatDate = (dateString) => {
        if (!dateString) {
            return "";
        }

        const date = new Date(dateString);

        const options = { month: 'long', day: 'numeric', weekday: 'long' };
        return new Intl.DateTimeFormat('ko-KR', options).format(date);
    };

    const handleCountClick = () => {
        if (isLoggedIn) navigate(`/events/${id}/profileBoard`);
        else setIsLoginModalOpen(true);
    }

    const fetchEvent = async (userId) => {
        try {
            // 이벤트 정보 가져오기
            const response = await axiosInstance.get(`/event/${id}`);

            const data = await response.data.eventList[0];
            setEvent(data);
            setTitle(data.event.title);
            setStartDate(data.event.startDate);
            setEndDate(data.event.endDate);
            setPlace(data.event.location);
            setAbout(data.event.description);
            setCreatorId(data.event.creatorId);
            setImageUrl(data.event.imageUrl || null)

            if (data.event.creatorId) {
                fetchCreator(data.event.creatorId);
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    const fetchStatus = async (userId) => {
        try {
            const response = await axiosInstance.get(`/event/${id}`);
            const data = response.data.eventList[0];

            if (userId !== null) {
                if (data.event.creatorId == userId) setStatus("host");
                else if (data.attendUserIds.includes(Number(userId))) setStatus("join");
                else if (data.unattendUserIds.includes(Number(userId))) setStatus("not join");
                else setStatus("default");
            } else setStatus("default");
        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        const loadEventData = async () => {
            if (isLoading) return;
            await fetchEvent();

            if (isLoggedIn && user?.userId) {
                await fetchStatus(user.userId);
            } else {
                setStatus("default");
            }
        };

        loadEventData();
    }, [isLoggedIn, user?.userId, id, isLoading]);

    useEffect(() => {
        console.log(`user ${user?.userId}'s status is ${status}`);
    }, [status]);

    const fetchCreator = async (creatorId) => {
        try {
            const response = await axiosInstance.get(`/user/${creatorId}/profile`);
            const data = response.data;

            setCreatorName(data.nickname);
            setCreatorProfileUrl(data.profileImageUrl);
            setCreatorCompany(data.company);
            setCreatorLink(data.linkUri);
            setCreatorIntroduction(data.introduction);
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleJoinClick = () => {
        if (!isLoggedIn) {
            setIsLoginModalOpen(true);
        } else {
            setCurrentAction("join");
            setIsStatusModalOpen(true);
        }
    };

    const handleNotJoinClick = () => {
        if (!isLoggedIn) {
            setIsLoginModalOpen(true);
        } else {
            setCurrentAction("not-join");
            setIsStatusModalOpen(true);
        }
    };

    const handleStatusChange = async () => {
        try {
            let response;
            if (currentAction === "join") {
                response = await axiosInstance.post(`/event/${id}/attend`);
            } else {
                response = await axiosInstance.post(`/event/${id}/unattend`);
            }

            if (response.status === 200) {
                window.location.reload(); // 새로고침
            } else {
                Toast.error(ERROR_MESSAGES.AUTH.UNAUTHORIZED);
            }
        } catch (error) {
            Toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
            console.error(error);
        } finally {
            setIsStatusModalOpen(false); // 모달 닫기
        }
    }

    const handleShare = async () => {
        try {
            const currentUrl = window.location.href;
            await navigator.clipboard.writeText(currentUrl);
            Toast.success("이벤트 링크가 클립보드에 복사되었습니다.");
        } catch (error) {
            console.error("링크 복사 실패: ", error);
            Toast.error("링크 복사에 실패했습니다.");
        }
    };

    const handleHostClick = () => {
        setIsHostModalOpen(true);
    };

    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
        setIsLoginModalOpen(false);
    }

    const handleEditClick = () => {
        const eventData = {
            id,
            title,
            about,
            startDate,
            endDate,
            place,
            imageUrl,
        };

        navigate(`/events/${id}/edit`, { state: eventData });
    }

    if (!event) return (
        <p>Loading...</p>
    );

    const count = event.attendUserIds.length;
    const startDateObject = new Date(startDate);
    const endDateObject = new Date(endDate);

    startDateObject.setHours(0, 0, 0, 0);
    endDateObject.setHours(0, 0, 0, 0);

    return (
        <>
            {isLoginModalOpen &&
                <LoginModal
                    isOpen={isLoginModalOpen}
                    setIsOpen={setIsLoginModalOpen}
                    onLoginSuccess={() => handleLoginSuccess()}
                    openSignup={() => {
                        setIsLoginModalOpen(false);
                        setIsSignupModalOpen(true);
                    }}
                    isRSVP={true}
                />}
            {isSignupModalOpen &&
                <SignupModal
                    isOpen={isSignupModalOpen}
                    setIsOpen={setIsSignupModalOpen}
                    openLogin={() => {
                        setIsSignupModalOpen(false);
                        setIsLoginModalOpen(true);
                    }}
                />}
            {isStatusModalOpen && (
                <StatusChangeModal
                    isOpen={isStatusModalOpen}
                    onClose={() => setIsStatusModalOpen(false)}
                    action={currentAction}
                    onConfirm={handleStatusChange}
                />
            )}
            {isHostModalOpen && (
                <div className="host-modal">
                    <div className="modal-overlay" onClick={() => setIsHostModalOpen(false)} />
                    <ProfileCard
                        profileUserId={creatorId}
                        name={creatorName}
                        company={creatorCompany}
                        about={creatorIntroduction}
                        profileImage={creatorProfileUrl}
                        contact={creatorLink}
                        isModal={true}
                        onClose={() => setIsHostModalOpen(false)}
                    />
                </div>
            )}
            <div className="event-detail-wrapper">
                <div className="event-detail">
                    <div className="image-container">
                        <div className={`image-placeholder ${imageUrl ? "has-image" : ""} cant-hover`}>
                            {(imageUrl) ? (
                                    <img src={imageUrl} className="event-image" alt="event-image"/>
                            ) : (<div className="empty-event-image">
                                    <p style={{ letterSpacing: "-0.02em" }}>YOU ARE</p>
                                    <p style={{ letterSpacing: "0.05em" }}>INVITED</p>
                                </div>
                            )}
                        </div>
                        <div style={{
                            display: "flex",
                            marginTop: "12px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            height: "37px"
                        }}>
                            <div className="count-container">
                                <img src={icPeople} width="16px" height="16px" style={{marginRight: "2px"}}/>
                                <p onClick={handleCountClick}>총 참여자&nbsp;</p><p>{count}명</p>
                            </div>
                            <div className="status-container">
                                {(status === "default") ? (
                                    <>
                                        <div className="join hover-btn" onClick={handleJoinClick}>
                                            <img src={icAttending} width="19px" height="19px"/>
                                            <p>참여</p>
                                        </div>
                                        <div className="not-join hover-btn" onClick={handleNotJoinClick}>
                                            <img src={icNotAttending} width="19px" height="19px"/>
                                            <p>불참</p>
                                        </div>
                                    </>
                                ) : ((status === "join") ? (
                                        <>
                                            <div className="join hover-btn" onMouseEnter={() => setShowTooltip(true)}
                                                 onMouseLeave={() => setShowTooltip(false)} onClick={handleNotJoinClick}
                                                 style={{width: "109px"}}>
                                                <img src={icAttending} width="19px" height="19px"/>
                                                <p>참여중</p>
                                            </div>
                                            {showTooltip && <Tooltip message="클릭 시 상태 변경"/>}
                                        </>
                                    ) : ((status === "not join") ? (
                                        <>
                                            <div className="not-join hover-btn"
                                                 onMouseEnter={() => setShowTooltip(true)}
                                                 onMouseLeave={() => setShowTooltip(false)} onClick={handleJoinClick}
                                                 style={{width: "109px"}}>
                                                <img src={icNotAttending} width="19px" height="19px"/>
                                                <p>미참여</p>
                                            </div>
                                            {showTooltip && <Tooltip message="클릭 시 상태 변경"/>}
                                        </>
                                    ) : (
                                        <>
                                            <div className="not-join hover-btn" onClick={handleEditClick}>
                                                <img src={icEdit} width="19px" height="19px"/>
                                                <p>수정</p>
                                            </div>
                                            {/*<div className="not-join hover-btn">*/}
                                            {/*    <img src={icSettings} width="19px" height="19px"/>*/}
                                            {/*    <p>관리</p>*/}
                                            {/*</div>*/}
                                        </>
                                    ))
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="content-container">
                        <div className="title-container" style={{width: "444px", flexDirection: "row"}}>
                            <p className="event-title">{title}</p>
                            <button
                                onMouseEnter={() => setIsShareHovered(true)}
                                onMouseLeave={() => setIsShareHovered(false)}
                                onClick={handleShare}
                                style={{marginLeft: "6px", marginTop: "8px"}}>
                                <img src={isShareHovered ? btnShareHover : btnShare} width="28px"
                                     height="28px"/>
                            </button>
                        </div>
                        <div className="contents detail">
                            <div className="date-container">
                                <div className="label">
                                    <img src={icCalendar} alt="icCalendar"/>
                                    {(
                                        startDateObject.getTime() === endDateObject.getTime()
                                    ) ? (
                                        <p>{formatDate(startDate)}</p>
                                    ) : (
                                        <p>{formatDate(startDate)}&nbsp;-&nbsp;{formatDate(endDate)}</p>
                                    )}
                                </div>
                            </div>
                            <div className="place-container">
                                <div className="label">
                                    <img src={icLocation} alt="icLocation"/>
                                    {place ? <p>{place}</p> : <p>추후공지</p>}
                                </div>
                            </div>
                            <div className="creator-container">
                                <div className="label">
                                    <img src={icHost} alt="icHost"/>
                                    <p className="hover-text" onClick={handleHostClick} style={{cursor: "pointer"}}>{creatorName}님이
                                        주최하였습니다.</p>
                                </div>
                            </div>
                            {(about) ? (<div className="about-container">
                                <div className="label">
                                    <img src={icMemo} alt="icMemo"/>
                                    <p>설명</p>
                                </div>
                                <p className="about"
                                   style={{marginTop: "10px", marginBottom: "24px"}}>{about}</p>
                            </div>) : <div style={{marginBottom: "24px"}} />}
                        </div>
                        <div className="feed-container">
                            <div className="label">
                                <p>소셜 피드</p>
                            </div>
                            {(!isLoggedIn) && <div className="feed-overlay">
                                <p className="caution">로그인 시 확인할 수 있습니다.</p>
                                <button className="web-header-btn hover-btn" onClick={() => setIsLoginModalOpen(true)}>로그인</button>
                            </div>}
                            <Feed
                                id={id}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventDetail;
