// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    box-sizing: border-box;
}

.modal-container .login-form, .modal-container .signup-form,
.modal-container .edit-form, .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    z-index: 1001;
    box-shadow: 0 2px 10px 5px #00000014;
    padding: 48px 28px;
}

.close-btn {
    position: fixed;
    top: 12px;
    right: 12px;
    cursor: pointer;
}

.login-guide p {
    margin: 0;
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/component/modal/LoginModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,MAAM;IACN,OAAO;IACP,oCAAoC;IACpC,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;;IAEI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,yBAAyB;IACzB,aAAa;IACb,oCAAoC;IACpC,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,UAAU;AACd","sourcesContent":[".modal-overlay {\n    position: fixed;\n    height: 100%;\n    width: 100%;\n    top: 0;\n    left: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    z-index: 999;\n    box-sizing: border-box;\n}\n\n.modal-container .login-form, .modal-container .signup-form,\n.modal-container .edit-form, .modal-content {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: #FFFFFF;\n    z-index: 1001;\n    box-shadow: 0 2px 10px 5px #00000014;\n    padding: 48px 28px;\n}\n\n.close-btn {\n    position: fixed;\n    top: 12px;\n    right: 12px;\n    cursor: pointer;\n}\n\n.login-guide p {\n    margin: 0;\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
