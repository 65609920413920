import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { storage } from "../../firebase";
import axiosInstance from "../../axiosInstance";
import "./ProfileEditModal.css"
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {Toast} from "../../style/StyledToastContainer.tsx";
import {ERROR_MESSAGES} from "../../constants/errorMessages";
import icClose from "../../style/icon/png/icClose.png";
import btnPhotoSettingsHover from "../../style/icon/png/btnPhotoSettings_hover.png";
import btnPhotoSettings from "../../style/icon/png/btnPhotoSettings_default.png";
import icNecessary from "../../style/icon/png/icNecessary.png";
import {TextareaFieldSmall, TextFieldSmall} from "../../style/components";
import icError from "../../style/icon/png/icError.png";

const ProfileEditModal = ({ userId, onClose }) => {
    const [profileImage, setProfileImage] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [isHovered, setIsHovered] = useState(false);

    const [name, setName] = useState("");
    const [company, setCompany] = useState("");
    const [link, setLink] = useState("");
    const [about, setAbout] = useState("");

    const [nameError, setNameError] = useState(false);
    const [companyError, setCompanyError] = useState(false);
    const [aboutError, setAboutError] = useState(false);

    const [isDisable, setIsDisable] = useState(true);

    const location = useLocation();

    const handleNameBlur = () => {
        if (name === "" || name.length < 2 || name.length > 20 || !(/^[a-zA-Z가-힣\s]+$/.test(name))) {
            return true;
        } else {
            return false;
        }
    }

    const handleCompanyBlur = () => {
        if (company === "" || (company.length < 2 && company.length > 50)) {
            return true;
        } else {
            return false;
        }
    }

    const handleAboutBlur = () => {
        if (about === "" || about.length > 100) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`/user/${userId}/profile`);

                const data = response.data;
                setName(data.nickname || "");
                setProfileImage(data.profileImageUrl || "");
                setCompany(data.company || "");
                setLink(data.linkUri || "");
                setAbout(data.introduction || "");
            } catch (error) {
                setProfileImage("");
                setCompany("");
                setLink("");
                setAbout("");
            }
        }

        fetchData();
    }, [userId]);

    useEffect(() => {
        if (nameError || companyError || aboutError || name === "" || company === "" || about === "" || about.length > 100) {
            setIsDisable(true);
        } else {
            setIsDisable(false);
        }
    }, [name, company, about, nameError, companyError, aboutError]);

    const handleImageUpload = async (file) => {
        const storageRef = ref(storage, `profileImages/${file.name}`);

        await uploadBytes(storageRef, file);

        return await getDownloadURL(storageRef);
    }

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                setProfileImage(reader.result);

                try {
                    const url = await handleImageUpload(file);
                    setImageUrl(url);
                    Toast.success("프로필 이미지가 성공적으로 업데이트되었습니다!");
                } catch (error) {
                    console.error("프로필 이미지 업로드 실패: ", error);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSaveProfile = async () => {
        try {
            const postData = {
                nickname: name,
                profileImageUrl: imageUrl || profileImage,
                company: company,
                linkUri: link,
                introduction: about
            }

            const response = await axiosInstance.post(`/user/${userId}/profile`, postData);

            if (response.status === 200) {
                Toast.success("프로필이 적용되었습니다");
                onClose();
                window.location.reload(); // 페이지 새로고침
            } else {
                Toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
            }

            console.log("프로필 저장 성공");
        } catch (error) {
            console.error("프로필 저장 실패: ", error.message);
        }
    };

    return (
        <div className="modal-container">
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="edit-form profile">
                <img className="close-btn" onClick={onClose} src={icClose} width="18px" height="18px"/>
                <div className="title">프로필 카드 수정하기</div>
                <div className="edit-profile-image">
                    {(profileImage && profileImage.trim()) ? (
                        <img
                            src={profileImage}
                            className="profile-image"
                        />) : (
                        <div className="empty-profile-image">
                            {name.slice(0, 1)}
                        </div>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        style={{display: 'none'}}
                        id="profile-image-input"
                    />
                    <button
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        onClick={() => document.getElementById('profile-image-input').click()}>
                        <img src={isHovered ? btnPhotoSettingsHover : btnPhotoSettings} width="32px" height="32px"/>
                    </button>
                </div>
                <div className="edit-form-input">
                    <div>
                        <div className="label"><p>이름</p><img src={icNecessary}/></div>
                        <TextFieldSmall
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onBlur={handleNameBlur}
                            onError={setNameError}
                            isRequired={true}
                        />
                        {nameError && (
                            <div className="alert" style={{ position: "absolute", marginTop: "2px" }}>
                                <img src={icError} width="16px" height="16px"/>
                                <p className="alert-text">최소 2글자 이상 입력하세요</p>
                            </div>
                        )}
                    </div>
                    <div>
                        <div>
                            <div className="label"><p>소속</p><img src={icNecessary}/></div>
                            <TextFieldSmall
                                type="text"
                                placeholder="ex: 우디고 / 예비창업가"
                                value={company}
                                onChange={(e) => setCompany(e.target.value)}
                                onBlur={handleCompanyBlur}
                                onError={handleCompanyBlur}
                                isRequired={true}
                            />
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="label"><p>링크</p></div>
                            <TextFieldSmall
                                type="url"
                                placeholder="링크드인, 소셜미디어, 랜딩 페이지 등 링크를 직접 작성해 주세요"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                            />
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="label"><p>소개</p><img src={icNecessary}/></div>
                            <TextareaFieldSmall
                                type="text"
                                placeholder="프로필 카드에 보여질 내용을 100자 이내로 작성하세요"
                                value={about}
                                onChange={(e) => setAbout(e.target.value)}
                                onBlur={handleAboutBlur}
                                onError={setAboutError}
                                isRequired={true}
                                isLong={true}
                            />
                        </div>
                    </div>
                    <button className={`login-button hover-btn ${isDisable ? 'disabled' : ''}`} onClick={handleSaveProfile}>작성 완료하기</button>
                </div>
            </div>
        </div>
    );
}

export default ProfileEditModal;
