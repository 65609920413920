// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-content {
    width: 400px;
    height: 158px;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modal-content .modal-guide {
    font-size: 18px;
    font-weight: 600;
    line-height: 25.2px;
    text-align: center;
    color: var(--text_neutral_01);
}

.modal-content button {
    margin: 0;
    padding: 0;
    width: 116px;
    height: 45px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.09px;
    text-align: center;
    color: var(--text_neutral_white);
    cursor: pointer;
}

.modal-content button.not-join {
    background-color: var(--text_color_red);
    position: relative;
}

.modal-content button.not-join:hover {
    background-color: #B14B4C;
}

.modal-content button.join {
    background-color: var(--brand_primary_01);
}

.modal-content button.join:hover {
    background-color: var(--brand_primary_03);
}

.modal-content button.cancel {
    background-color: var(--text_neutral_03);
}

.hover-btn-gray:hover {
    background-color: var(--text_neutral_01) !important;
}`, "",{"version":3,"sources":["webpack://./src/component/modal/StatusChangeModal.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,6BAA6B;AACjC;;AAEA;IACI,SAAS;IACT,UAAU;IACV,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,kBAAkB;IAClB,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,uCAAuC;IACvC,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,mDAAmD;AACvD","sourcesContent":[".modal-content {\n    width: 400px;\n    height: 158px;\n    border-radius: 8px;\n    background-color: #FFFFFF;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.modal-content .modal-guide {\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 25.2px;\n    text-align: center;\n    color: var(--text_neutral_01);\n}\n\n.modal-content button {\n    margin: 0;\n    padding: 0;\n    width: 116px;\n    height: 45px;\n    border-radius: 6px;\n    font-size: 16px;\n    font-weight: 700;\n    line-height: 19.09px;\n    text-align: center;\n    color: var(--text_neutral_white);\n    cursor: pointer;\n}\n\n.modal-content button.not-join {\n    background-color: var(--text_color_red);\n    position: relative;\n}\n\n.modal-content button.not-join:hover {\n    background-color: #B14B4C;\n}\n\n.modal-content button.join {\n    background-color: var(--brand_primary_01);\n}\n\n.modal-content button.join:hover {\n    background-color: var(--brand_primary_03);\n}\n\n.modal-content button.cancel {\n    background-color: var(--text_neutral_03);\n}\n\n.hover-btn-gray:hover {\n    background-color: var(--text_neutral_01) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
