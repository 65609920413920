import React, { createContext, useContext, useState, useEffect } from "react";
import axiosInstance from "../axiosInstance";
import { ERROR_MESSAGES } from "../constants/errorMessages";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkLoginStatus = async () => {
            setIsLoading(true);
            try {
                const accessToken = localStorage.getItem("accessToken");
                const refreshToken = localStorage.getItem("refreshToken");
                const accessTokenExpiresAt = parseInt(localStorage.getItem("accessTokenExpiresAt"), 10);
                const refreshTokenExpiresAt = parseInt(localStorage.getItem("refreshTokenExpiresAt"), 10);
                const username = localStorage.getItem("username");
                const userId = localStorage.getItem("userId");

                // 이미 로그인 상태가 확인되었거나 유효한 토큰이 있으면 상태 유지
                if (accessToken && refreshToken &&
                    accessTokenExpiresAt && Date.now() < accessTokenExpiresAt &&
                    refreshTokenExpiresAt && Date.now() < refreshTokenExpiresAt) {
                    setIsLoggedIn(true);
                    if (username && userId) {
                        setUser({username, userId});
                    }
                    return;
                }

                // accessToken은 만료, refreshToken은 만료되지 않았으면 토큰 갱신
                if (refreshToken && Date.now() < refreshTokenExpiresAt) {
                    const response = await axiosInstance.post('/check-token', {
                        accessToken,
                        refreshToken
                    });

                    if (response.status === 200) {
                        const { accessToken, accessTokenExpiresIn, refreshToken, refreshTokenExpiresIn } = response.data;
                        localStorage.setItem("accessToken", accessToken);
                        localStorage.setItem("accessTokenExpiresAt", Date.now() + accessTokenExpiresIn * 1000);

                        if (refreshToken) {
                            localStorage.setItem("refreshToken", refreshToken);
                            localStorage.setItem("refreshTokenExpiresAt", Date.now() + refreshTokenExpiresIn * 1000);
                        }

                        setIsLoggedIn(true);
                        if (username && userId) {
                            setUser({username, userId});
                        }
                    } else {
                        logout();
                    }
                } else {
                    console.log("Refresh token expired.");
                    logout();
                }
            } catch (error) {
                console.error(error);
                logout();
            } finally {
                setIsLoading(false);
            }
        }

        checkLoginStatus();
    }, []);

    const login = async (credentials) => {
        try {
            const response = await axiosInstance.post('/login', credentials); //로그인

            const { username, userId, accessToken, accessTokenExpiresIn, refreshToken, refreshTokenExpiresIn } = response.data;

            localStorage.setItem("username", username);
            localStorage.setItem("userId", userId);

            if (username && userId) {
                setUser({username, userId});
            }
            setIsLoggedIn(true);
            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", refreshToken);
            localStorage.setItem("accessTokenExpiresAt", Date.now() + accessTokenExpiresIn * 1000);
            localStorage.setItem("refreshTokenExpiresAt", Date.now() + refreshTokenExpiresIn * 1000);

            console.log("Login success: ", userId);
        } catch (error) {
            if (error.response && error.response.status === 404) { // 로그인 시도한 이메일이 존재하지 않을 때
                throw new Error(ERROR_MESSAGES.AUTH.UNREGISTERED_EMAIL);
            }

            console.error(error.message);
            throw new Error(ERROR_MESSAGES.AUTH.LOGIN_FAILED);
        }
    };

    const logout = async () => {
        try {
            const accessToken = localStorage.getItem("accessToken");
            const refreshToken = localStorage.getItem("refreshToken");

            if (!accessToken || !refreshToken) {
                setIsLoggedIn(false);
                setUser(null);
                localStorage.removeItem("username");
                localStorage.removeItem("userId");
            } else {
                await axiosInstance.post('/sign-out', {
                    accessToken,
                    refreshToken,
                });

                setIsLoggedIn(false);
                setUser(null);
                localStorage.removeItem("username");
                localStorage.removeItem("userId");
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("accessTokenExpiresAt");
                localStorage.removeItem("refreshTokenExpiresAt");
            }
        } catch (error) {
            console.error(error.message);
            throw new Error(ERROR_MESSAGES.AUTH.LOGOUT_FAILED);
        }
    }

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, user, setUser, login, logout, isLoading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
