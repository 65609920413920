import React, { useState, useEffect } from "react";
import ProfileCard from "./ProfileCard";
import axiosInstance from "../axiosInstance";
import {Toast} from "../style/StyledToastContainer.tsx";
import './Participants.css';
import {useAuth} from "../contexts/AuthContext";

const Participants = ({id}) => {
    const { isLoggedIn } = useAuth();
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        const fetchParticipants = async () => {
            try {
                const response = await axiosInstance.get(`/event/${id}`);
                const eventData = await response.data.eventList[0];

                const creatorId = eventData.event.creatorId;

                // attendUserIds가 undefined인 경우 빈 배열로 설정
                const attendUserIds = eventData.attendUserIds || [];

                const fetchProfile = async (userId) => {
                    const profileResponse = await axiosInstance.get(`/user/${userId}/profile`);
                    return {
                        id: userId,
                        nickname: profileResponse.data.nickname || "",
                        profileImageUrl: profileResponse.data.profileImageUrl || null,
                        company: profileResponse.data.company || null,
                        introduction: profileResponse.data.introduction || "안녕하세요.\n만나 뵙게 되어 반갑습니다",
                        linkUri: profileResponse.data.linkUri || null,
                        isHost: userId === eventData.event.creatorId
                    };
                };

                // 참가자 프로필 가져오기
                const profiles = await Promise.all(attendUserIds.map(fetchProfile));

                // 호스트 프로필 추가
                if (creatorId && !attendUserIds.includes(creatorId)) {
                    const creatorProfile = await fetchProfile(creatorId);
                    profiles.push(creatorProfile);
                }

                // 호스트 먼저 정렬
                const sortedProfiles = profiles.sort((a, b) => {
                    return (b.isHost === true) - (a.isHost === true);
                });

                setParticipants(sortedProfiles);
            } catch (error) {
                Toast.error(error.message);
            }
        };

        if (isLoggedIn) {
            fetchParticipants();
        }
    }, [id, isLoggedIn]);

    return (
        <div className="profile-card-container">
            {participants.length > 0 && (
                participants.map((participant, index) => {
                    return (
                        <ProfileCard
                            key={index}
                            profileUserId={participant.id}
                            name={participant.nickname}
                            company={participant.company}
                            about={participant.introduction}
                            profileImage={participant.profileImageUrl}
                            contact={participant.linkUri}
                            isHost={participant.isHost}
                        />
                    );
                })
            )}
        </div>
    );
};

export default Participants;