import React, { useState, useEffect, useRef } from "react";
import './components.css';
import './colors.css';

const PostInputField = ({ profileImageUrl = null, nickname, inputValue, onChange, onClick }) => {
    const [styleType, setStyleType] = useState("inactive");

    const onFocus = () => {
        setStyleType("active");
    };

    const onBlur = () => {
        setStyleType("inactive");
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();  // Enter로 submit하는 기본 동작 방지
            onClick();  // Enter를 눌렀을 때 onClick 실행
        }
    };

    return (
        <div className={`postInputField ${styleType}`}>
            {profileImageUrl ? (
                <img src={profileImageUrl} alt="Profile" />
            ) : (
                <div className="empty-profile">
                    {nickname?.slice(0, 1)}
                </div>
            )}
            <input
                type="text"
                value={inputValue}
                placeholder="포스트를 작성해 주세요"
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={handleKeyPress}
            />
            <button onClick={onClick} style={{cursor: 'pointer'}}>등록</button>
        </div>
    )
};

const TextField = ({ type = "text", placeholder, value, onChange, onBlur, isRequired = false, onError, maxLength, showCount = false, unmatchError = false, autoComplete}) => {
    const [styleType, setStyleType] = useState("active");

    const handleBlur = (e) => {
        if (onBlur)  {
            const validationError = onBlur(e);
            if (
                (typeof validationError === 'boolean' && validationError === true) ||
                (typeof validationError === 'object' && validationError && Object.values(validationError).some(err => err === true))
            ) {
                setStyleType("error");
            } else {
                setStyleType("active");
            }
        }
    };

    const handleChange = (e) => {
        if (onChange) {
            onChange(e);
            if (typeof onError === 'function') {
                const validationError = onChange(e);

                if (validationError === true) {
                    setStyleType("error");
                } else {
                    setStyleType("active");
                }
            }
        }
    };

    const onFocus = () => {
        setStyleType("active");
    };

    useEffect(() => {
        if (unmatchError) setStyleType("error");
        else setStyleType("active");
    }, [unmatchError])

    return (
        <div className={`textField ${styleType}`}>
            <input
                type={type}
                value={value}
                onChange={handleChange}
                onFocus={onFocus}
                onBlur={handleBlur}
                placeholder={placeholder}
                required={isRequired}
                maxLength={maxLength ? maxLength : undefined}
                autoComplete={autoComplete}
            />
            {(maxLength && showCount) && (
                <span className="char-count">
                    {value.length}/{maxLength}
                </span>
            )}
        </div>
    );
};

const TextFieldSmall = ({ type = "text", placeholder, value, onChange, onBlur, onError, onClick, isRequired = false, isLong = false, isWide = false, readOnly = false }) => {
    const [styleType, setStyleType] = useState("");
    const [hasError, setHasError] = useState("");

    const handleBlur = (e) => {
        if (onBlur)  {
            const validationError = onBlur();
            if (typeof onError === 'function') {
                onError(validationError);
            }

            if (validationError === true) {
                setHasError("error");
            } else {
                setHasError("");
            }
        }
    };

    const onMouseEnter = () => {
        setStyleType("hover");
    };

    const onMouseLeave = () => {
        setStyleType("");
    }

    return (
        <div className={`textFieldSmall ${hasError} ${styleType} ${isLong ? 'lengthy' : ''} ${isWide ? 'wide' : ''}`}>
            <input
                type={type}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onFocus={onMouseLeave}
                placeholder={placeholder}
                required={isRequired}
            />
        </div>
    );
};

const TextareaFieldSmall = ({ type = "text", placeholder, value, onChange, onBlur, onError, onClick, isRequired = false, isLong = false, isWide = false, readOnly = false }) => {
    const [styleType, setStyleType] = useState("");
    const [hasError, setHasError] = useState("");

    const handleBlur = (e) => {
        if (onBlur)  {
            const validationError = onBlur();
            if (typeof onError === 'function') {
                onError(validationError);
            }

            if (validationError === true) {
                setHasError("error");
            } else {
                setHasError("");
            }
        }
    };

    const onMouseEnter = () => {
        setStyleType("hover");
    };

    const onMouseLeave = () => {
        setStyleType("");
    }

    const inputRef = useRef(null);

    const adjustHeight = () => {
        const input = inputRef.current;
        if (input) {
            input.style.height = 'auto';
            input.style.height = `${input.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [value]);

    return (
        <div className={`textFieldSmall ${hasError} ${styleType} ${isLong ? 'lengthy' : ''} ${isWide ? 'wide' : ''}`}>
            <textarea
                rows={1}
                ref={inputRef}
                value={value}
                onChange={onChange}
                onBlur={handleBlur}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onFocus={onMouseLeave}
                placeholder={placeholder}
                required={isRequired}
            />
        </div>
    );
};

const TextareaField = ({ placeholder, value, onChange }) => {
    const inputRef = useRef(null);

    const adjustHeight = () => {
        const input = inputRef.current;
        if (input) {
            input.style.height = 'auto';
            input.style.height = `${input.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [value]);

    const [styleType, setStyleType] = useState("");

    const onMouseEnter = () => {
        setStyleType("hover");
    };

    const onMouseLeave = () => {
        setStyleType("");
    }

    return (
        <div className={`textFieldSmall textarea ${styleType}`}>
            <textarea
                rows={1}
                ref={inputRef}
                value={value}
                onChange={onChange}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onFocus={onMouseLeave}
                placeholder={placeholder}
            />
        </div>
    );
};

const CalendarField = ({ type, value }) => {
    const [styleType, setStyleType] = useState("");

    const onMouseEnter = () => {
        setStyleType("hover");
    };

    const onMouseLeave = () => {
        setStyleType("");
    }

    return (
        <div className={`calendar textFieldSmall ${styleType}`}>
            <p>{type}</p>
            <input
                value={value}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onFocus={onMouseLeave}
                readOnly
            />
        </div>
    );
};

const Tooltip = ({ message }) => {
    return (
        <div className="tooltip">
            <p>{message}</p>
        </div>
    );
};

const Confirm = ({ message, btnMessage="확인", buttonNum=1, positive=true }) => {
    return (
        <div className="confirm">
            <p>{message}</p>
            {(buttonNum === 1) ? (
                <button className="one-btn hover-btn">{btnMessage}</button>
            ) : (
                <div className="btn-wrapper">
                    <button className={`two-btn hover-btn ${!positive ? 'negative' : ''}`}>{btnMessage}</button>
                    <button className="two-btn cancel hover-btn">취소</button>
                </div>
            )}
        </div>
    );
};

export { PostInputField, TextField, TextFieldSmall, TextareaFieldSmall, TextareaField, CalendarField, Tooltip, Confirm };