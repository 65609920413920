export const ERROR_MESSAGES = {
    AUTH: {
        LOGIN_FAILED: "로그인에 실패했습니다.",
        EMAIL_EXISTS: "이미 존재하는 이메일입니다.",
        SIGNUP_FAILED: "회원가입에 실패했습니다.",
        LOGOUT_FAILED: "로그아웃 중 오류가 발생했습니다.",
        UNAUTHORIZED: "인증되지 않은 사용자입니다.",
        UNREGISTERED_EMAIL: "등록되지 않은 이메일입니다. 관리자에게 문의해주세요"
    },
    NETWORK: {
        TIMEOUT: "네트워크 요청 시간이 초과되었습니다.",
        SERVER_ERROR: "서버 오류가 발생했습니다.",
        NOT_FOUND: "요청한 리소스를 찾을 수 없습니다.",
    },
    VALIDATION: {
        REQUIRED_FIELD: "필수 입력 항목입니다.",
        PASSWORD_MISMATCH: "비밀번호가 일치하지 않습니다.",
        INVALID_EMAIL: "유효한 이메일 주소를 입력해 주세요.",
    },
    GENERAL: {
        UNKNOWN_ERROR: "알 수 없는 오류가 발생했습니다.",
    },
};