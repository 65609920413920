import Calendar from "react-calendar";
import styled from "styled-components";
import "react-calendar/dist/Calendar.css";
import "./colors.css"

export const StyledCalendarWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    .react-calendar {
        background-color: var(--base_white);
    }
    
    .react-calendar__month-view abbr {
        color: var(--text_neutral_01);
        font-weight: 400;
    }
    
    .react-calendar__navigation button {
        font-weight: 600;
    }
    
    .react-calendar__month-view__weekdays abbr {
        text-decoration: none;
        font-weight: 700;
    }
    
    .react-calendar__month-view__days__day--weekend abbr {
        color: var(--text_color_red);
    }

    .react-calendar__month-view__days__day--neighboringMonth,
    .react-calendar__decade-view__years__year--neighboringDecade,
    .react-calendar__century-view__decades__decade--neighboringCentury {
        abbr {
            color: var(--text_neutral_05);
        }
    }
    
    .react-calendar__tile:disabled {
        abbr {
            color: var(--text_neutral_05);
        }
    }
    
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
        background: var(--base_03);
    }
    
    .react-calendar__tile--active:enabled {
        background: var(--brand_primary_01);
        abbr {
            color: var(--text_neutral_white);
        }
    }
    
    .react-calendar__tile--active:enabled:hover {
        background: var(--brand_primary_03);
        abbr {
            color: var(--text_neutral_white);
        }
    }
    
    .react-calendar__tile--now {
        background: var(--base_white);
    }
`

export const StyledCalendar = styled(Calendar)``;

export const StyledToday = styled.div`
    background-color: transparent;
    border: 1px solid var(--text_neutral_01);
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-48%, -105%);
`;