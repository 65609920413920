import React, { useState } from 'react';
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import "./Login.css";
import brand from "../style/icon/png/brand.png";
import icError from "../style/icon/png/icError.png";
import {TextField} from "../style/components";
import {showMessenger} from "@channel.io/channel-web-sdk-loader";

const Login = () => {
    const { login } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [hasEmailError, setHasEmailError] = useState(false);
    const [hasPasswordError, setHasPasswordError] = useState(false);
    const [hasLoginError, setHasLoginError] = useState(false);
    const navigate = useNavigate();

    const handleLogin = async (event) => {
        event.preventDefault();

        if (email === "") {
            setHasEmailError(true);
            return;
        } else {
            setHasEmailError(false);
        }

        if (password === "") {
            setHasPasswordError(true);
            return;
        } else {
            setHasPasswordError(false);
        }

        try {
            await login({ username: email, password: password });
            navigate("/");
        } catch (error) {
            setHasLoginError(true);
            console.error(error.message);
        }
    };

    return (
        <div className="login-container">
            <form className="login-form" onSubmit={handleLogin} noValidate>
                <div className="form-group">
                    <img src={brand} width="130px" height="42px" />
                    <div className="brand-text">내 손으로 만드는 성공적인 이벤트</div>
                    <div className="welcome">Wavely에 오신 것을 환영합니다</div>
                    <div className="login-guide">아래에서 로그인 또는 가입하세요</div>
                    <div className="form-input">
                        <TextField
                            type="email"
                            placeholder="이메일 주소"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            type="password"
                            placeholder="비밀번호"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className="login-sub">
                    <Link className="to-signup hover-text" to="/signup" type="button">회원가입</Link>
                    <div className="line" />
                    <div className="hover-text" onClick={showMessenger}>비밀번호 찾기</div>
                </div>
                <button className="login-button hover-btn" type="submit">로그인</button>
                <div className="agreement">
                    <p>계정 생성 시&nbsp;</p>
                    <a className="terms hover-text" href="https://woodigo.notion.site/Wavely-ef038315c15e49079280089b23308362" target="_blank" rel="noopener noreferrer">이용 약관</a>
                    <p>&nbsp;및&nbsp;</p>
                    <a className="terms hover-text" href="https://woodigo.notion.site/57f13de24bc4434094d609da8be5e38b" target="_blank" rel="noopener noreferrer">개인정보 처리방침</a>
                    <p>에 동의하는 것으로 간주합니다.</p>
                </div>
                {hasLoginError ? (
                    <div className="alert">
                        <img src={icError} width="16px" height="16px" />
                        <p className="alert-text">등록되지 않은 이메일입니다. 관리자에게 문의해 주세요.</p>
                    </div>
                ) : (hasEmailError ? (
                    <div className="alert">
                        <img src={icError} width="16px" height="16px" />
                        <p className="alert-text">이메일을 입력해 주세요.</p>
                    </div>
                ) : (hasPasswordError ? (
                    <div className="alert">
                        <img src={icError} width="16px" height="16px" />
                        <p className="alert-text">비밀번호를 입력해 주세요.</p>
                    </div>
                ) : (
                    <div className="alert-placeholder"></div>
                )))}
            </form>
        </div>
    )
};

export default Login;