import React, { useEffect } from 'react';

const ContactPage = () => {
    const loadTallyScript = () => {
        const script = document.createElement('script');
        script.src = 'https://tally.so/widgets/embed.js';
        script.async = true;

        script.onload = () => {
            if (window.Tally) {
                window.Tally.loadEmbeds(); // Tally 위젯을 로드
            }
        };

        script.onerror = () => {
            console.error("Tally script loading failed");
        };

        document.body.appendChild(script);
    };

    useEffect(() => {
        loadTallyScript();
    }, []);

    return (
        <div style={{ padding: "0 140px" }}>
            <iframe
                data-tally-src="https://tally.so/embed/mYrxx0?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
                loading="lazy"
                width="100%"
                height="661"
                frameBorder="0"
                marginHeight="0"
                marginWidth="0"
                title="사용 문의하기"
            ></iframe>
        </div>
    );
};

export default ContactPage;
