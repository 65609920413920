import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axiosInstance from "../axiosInstance";
import {Toast} from "../style/StyledToastContainer.tsx";
import EventCard from "../component/EventCard";
import "./DashBoard.css";
import "../style/colors.css";
import imgCalendar from "../style/icon/png/imgCalendar.png";

const DashBoard = () => {
    const [events, setEvents] = useState([]);
    const [filter, setFilter] = useState('upcoming');

    const navigate = useNavigate();

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                // 이벤트 목록 가져오기
                const response = await axiosInstance.get('/event');

                setEvents(response.data.eventList || []);
            } catch (error) {
                Toast.error(error.message);
                console.error(error.message);
            }
        };

        fetchEvents();
    }, []);

    const formatDate = (dateString) => {
        if (!dateString) {
            return "";
        }

        const date = new Date(dateString);

        const options = { month: 'long', day: 'numeric', weekday: 'long' };
        return new Intl.DateTimeFormat('ko-KR', options).format(date);
    };

    const filteredEvents = (events || []).filter(eventData => {
        const eventDate = new Date(eventData.event.startDate);
        eventDate.setHours(0, 0, 0, 0);

        const now = new Date();
        now.setHours(0, 0, 0, 0);

        if (filter === 'upcoming') {
            return eventDate >= now;
        }
        else return eventDate < now;
    })
        .sort((a, b) => new Date(b.event.createdAt) - new Date(a.event.createdAt));

    const handleCreateEventClick = () => {
        navigate("/events/create");
    }

    return (
        <div className="dashboard">
            <div className="dashboard-guide">대시 보드에서 모든 이벤트를 관리하세요</div>
            <div className="container">
                <div className="top">
                    <p>모든 이벤트</p>
                    <div className="filter">
                        <button onClick={() => setFilter('upcoming')}
                                className={filter === 'upcoming' ? 'active' : ''}>예정된
                        </button>
                        <button onClick={() => setFilter('past')} className={filter === 'past' ? 'active' : ''}>지난
                        </button>
                    </div>
                </div>
                <div className="event-list-wrapper">
                    {(filteredEvents && filteredEvents.length > 0) ? (
                        <div className="event-list-container">
                            {filteredEvents.map(eventData => {
                                    return (
                                        <EventCard
                                            key={eventData.event.id}
                                            id={eventData.event.id}
                                            title={eventData.event.title}
                                            startDate={formatDate(eventData.event.startDate)}
                                            location={eventData.event.location}
                                            imageUrl={eventData.event.imageUrl}
                                            creatorId={eventData.event.creatorId}
                                            count={eventData.attendUserIds.length}
                                        />
                                    );
                                }
                            )}
                            {(filter === 'upcoming' && filteredEvents.length > 0) && <div className="event-card create">
                                <button onClick={handleCreateEventClick} className="create-event">
                                    <svg width="34px"
                                         height="34px"
                                         viewBox="0 0 34 34">
                                        <path
                                            d="M15.8066 28.9H4.26316C3.36579 28.9 2.59732 28.5674 1.95774 27.9021C1.31925 27.2357 1 26.435 1 25.5V6.8C1 5.865 1.31925 5.06487 1.95774 4.3996C2.59732 3.7332 3.36579 3.4 4.26316 3.4H5.89474V0H9.15789V3.4H18.9474V0H22.2105V3.4H23.8421C24.7395 3.4 25.5079 3.7332 26.1475 4.3996C26.786 5.06487 27.1053 5.865 27.1053 6.8V17.1275C26.8333 17.0708 26.5685 17.0351 26.3107 17.0204C26.0518 17.0068 25.7728 17 25.4737 17C25.1746 17 24.8961 17.0068 24.6383 17.0204C24.3794 17.0351 24.114 17.0708 23.8421 17.1275V11.9H4.26316V25.5H15.8066C15.7522 25.7833 15.7179 26.0599 15.7038 26.3296C15.6907 26.5982 15.6842 26.8883 15.6842 27.2C15.6842 27.5117 15.6907 27.8018 15.7038 28.0704C15.7179 28.3401 15.7522 28.6167 15.8066 28.9ZM23.8421 34V28.9H18.9474V25.5H23.8421V20.4H27.1053V25.5H32V28.9H27.1053V34H23.8421Z"
                                            fill="currentColor"/>
                                    </svg>
                                    <p>이벤트 만들기</p>
                                </button>
                            </div>}
                        </div>
                    ) : (
                        <div className="empty-list-container">
                            <img src={imgCalendar} width="140px" height="140px"/>
                            {(filter === 'upcoming') ? (
                                <div className="no-event">다가오는 이벤트가 없습니다</div>
                            ) : (
                                <div className="no-event">지난 이벤트가 없습니다</div>
                            )
                            }
                            <div className="no-event-guide">기억에 남는 이벤트를 주최해보세요!</div>
                            <button className="create-event-btn hover-btn-white" onClick={handleCreateEventClick}>이벤트 만들기</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashBoard;