import React, {useEffect, useState} from 'react';
import { ERROR_MESSAGES } from "../../constants/errorMessages";
import {Toast} from "../../style/StyledToastContainer.tsx";
import axiosInstance from "../../axiosInstance";
import './LoginModal.css';
import brand from "../../style/icon/png/brand.png";
import {TextField} from "../../style/components";
import icError from "../../style/icon/png/icError.png";
import icClose from "../../style/icon/png/icClose.png";

const Signup = ({ isOpen, setIsOpen, openLogin }) => {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [emailError, setEmailError] = useState("");
    const [nameError, setNameError] = useState("");
    const [passwordError, setPasswordError] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false
    });
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [isChecked, setIsChecked] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleEmailBlur = async () => {
        try {
            const emailRegex = /^[0-9a-zA-Z]+([._-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
            if (!emailRegex.test(email)) {
                setEmailError("올바르지 않은 이메일 형식입니다.");
                return true;
            }

            const response = await axiosInstance.post('/user/email', {email});

            if (response.status === 200 && !response.data.isExist) {
                setEmailError("");
                return false;
            } else {
                setEmailError("이미 사용 중인 이메일입니다.");
                return true;
            }
        } catch (error) {
            console.error("Error checking email: ", error);
        }
    };

    const handleNameBlur = () => {
        const nameRegex = /^[a-zA-Z가-힣\s]{2,20}$/;

        if (!nameRegex.test(name)) {
            setNameError("이름은 한글 또는 영문(띄어쓰기 포함) 2~20자여야 합니다.");
            return true;
        } else {
            setNameError("");
            return false;
        }
    };

    const handlePasswordBlur = () => {
        const lengthValid = password.length >= 8 && password.length <= 20;
        const uppercaseValid = /[A-Z]/.test(password);
        const lowercaseValid = /[a-z]/.test(password);
        const numberValid = /[0-9]/.test(password);
        const specialValid = /[!@#$%^&*]/.test(password);

        const errors = {
            length: !lengthValid,
            uppercase: !uppercaseValid,
            lowercase: !lowercaseValid,
            number: !numberValid,
            special: !specialValid,
            match: false,
        };

        setPasswordError(errors);
        return errors;
    };

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);

        if (confirmPassword !== "") {
            if (value !== confirmPassword) {
                setConfirmPasswordError("비밀번호가 일치하지 않습니다.");
                setPasswordError((prevErrors) => ({
                    ...prevErrors,
                    match: true
                }));
                return true;
            } else {
                setConfirmPasswordError("");
                setPasswordError((prevErrors) => ({
                    ...prevErrors,
                    match: false
                }));
                return false;
            }
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);

        if (value !== password) {
            setConfirmPasswordError("비밀번호가 일치하지 않습니다.");
            setPasswordError((prevErrors) => ({
                ...prevErrors,
                match: true
            }));
            return true;
        } else {
            setConfirmPasswordError("");
            setPasswordError((prevErrors) => ({
                ...prevErrors,
                match: false
            }));
            return false;
        }
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    }

    useEffect(() => {
        const hasPasswordError = passwordError && Object.values(passwordError).some((value) => value === true);

        if (nameError === "" && emailError === "" && !hasPasswordError && !confirmPasswordError && isChecked
            && name !== "" && email !== "" && password !== "" && confirmPassword !== "") {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    }, [isChecked, nameError, emailError, passwordError, confirmPasswordError, name, email, password, confirmPassword]);

    const handleSignup = async (event) => {
        event.preventDefault();

        const payload = {
            username: email,
            nickname: name,
            password: password
        };

        try {
            // 회원가입
            const response = await axiosInstance.post('/signup', payload);
            if (response.status === 200) {
                console.log("회원가입 성공: " + response.data.username);
                openLogin();
            } else {
                Toast.error(ERROR_MESSAGES.AUTH.SIGNUP_FAILED);
            }
        } catch (error) {
            Toast.error(ERROR_MESSAGES.AUTH.SIGNUP_FAILED);
            console.error("회원가입 실패: ", error.message);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-container">
            <div className="modal-overlay" onClick={handleClose}></div>
            <form className="signup-form" onSubmit={handleSignup}>
                <img className="close-btn" onClick={handleClose} src={icClose} width="18px" height="18px"/>
                <div className="form-group">
                    <img src={brand} width="130px" height="42px"/>
                    <div className="signup-text">회원가입</div>
                    <div className="form-input">
                        <div>
                            <p className="label">이메일</p>
                            <TextField
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                onBlur={handleEmailBlur}
                                placeholder="아이디로 사용할 이메일을 입력해 주세요"
                                isRequired={true}
                                onError={setEmailError}
                            />
                            {emailError && (
                                <div className="alert">
                                    <img src={icError} width="16px" height="16px"/>
                                    <p className="alert-text">{emailError}</p>
                                </div>
                            )}
                        </div>
                        <div>
                            <p className="label">이름</p>
                            <TextField
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onBlur={handleNameBlur}
                                placeholder="한글, 영문 2-20자"
                                isRequired={true}
                                onError={setNameError}
                                maxLength={20}
                                showCount={true}
                            />
                            {nameError && (
                                <div className="alert">
                                    <img src={icError} width="16px" height="16px"/>
                                    <p className="alert-text">{nameError}</p>
                                </div>
                            )}
                        </div>
                        <div>
                            <p className="label">비밀번호</p>
                            <div className="password-input">
                                <TextField
                                    type="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                    onBlur={handlePasswordBlur}
                                    placeholder="영문, 숫자, 특수문자가 모두 들어간 8-20자"
                                    isRequired={true}
                                    onError={setPasswordError}
                                    maxLength={20}
                                    unmatchError={confirmPasswordError !== ""}
                                />
                                <TextField
                                    type="password"
                                    value={confirmPassword}
                                    onChange={handleConfirmPasswordChange}
                                    placeholder="비밀번호를 한 번 더 입력해 주세요"
                                    isRequired={true}
                                    onError={setConfirmPasswordError}
                                    maxLength={20}
                                />
                            </div>
                            {passwordError && passwordError.length && (
                                <div className="alert">
                                    <img src={icError} width="16px" height="16px"/>
                                    <p className="alert-text">최소 8글자 이상 20자 입력하세요</p>
                                </div>
                            )}
                            {passwordError && (passwordError.uppercase || passwordError.lowercase) && (
                                <div className="alert">
                                    <img src={icError} width="16px" height="16px"/>
                                    <p className="alert-text">영문 대문자와 소문자를 포함하세요</p>
                                </div>
                            )}
                            {passwordError && passwordError.number && (
                                <div className="alert">
                                    <img src={icError} width="16px" height="16px"/>
                                    <p className="alert-text">숫자를 포함하세요</p>
                                </div>
                            )}
                            {passwordError && passwordError.special && (
                                <div className="alert">
                                    <img src={icError} width="16px" height="16px"/>
                                    <p className="alert-text">특수문자(! @ # $ % ^ & * 등)를 포함해 주세요</p>
                                </div>
                            )}
                            {passwordError && passwordError.match && (
                                <div className="alert">
                                    <img src={icError} width="16px" height="16px"/>
                                    <p className="alert-text">비밀번호가 일치하지 않습니다</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="agree-check">
                    <input
                        type="checkbox"
                        id="agreeCheckbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                    <label htmlFor="agreeCheckbox">[필수] 개인정보 수집 및 이용 동의</label>
                </div>
                <button className={`login-button hover-btn ${isDisabled ? 'disabled' : ''}`} type="submit">가입 완료하기
                </button>
                <div className="agreement">
                    <p>계정 생성 시&nbsp;</p>
                    <a className="terms hover-text"
                       href="https://woodigo.notion.site/Wavely-ef038315c15e49079280089b23308362" target="_blank"
                       rel="noopener noreferrer">이용 약관</a>
                    <p>&nbsp;및&nbsp;</p>
                    <a className="terms hover-text" href="https://woodigo.notion.site/57f13de24bc4434094d609da8be5e38b"
                       target="_blank" rel="noopener noreferrer">개인정보 처리방침</a>
                    <p>에 동의하는 것으로 간주합니다.</p>
                </div>
            </form>
        </div>
    );
};

export default Signup;