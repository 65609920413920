import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import "./EventCard.css";
import axiosInstance from "../axiosInstance";
import icCalendar from "../style/icon/png/icCalendar.png";
import icLocation from "../style/icon/png/icLocation.png";
import icHost from "../style/icon/png/icHost.png";
import icPeople from "../style/icon/png/icPeople.png";
import imgHosting from "../style/icon/png/imgHosting.png";

const EventCard = ({ id, title, startDate, location, imageUrl, creatorId, count }) => {
    const { user, isLoading } = useAuth();
    const navigate = useNavigate();

    const [isHost, setIsHost] = useState(false);
    const [creatorName, setCreatorName] = useState("");

    useEffect(() => {
        if (!isLoading && user && user.userId && (user.userId == creatorId)) {
            setIsHost(true);
        };

        const fetchCreator = async () => {
            try {
                const response = await axiosInstance.get(`/user/${creatorId}/profile`);
                const data = response.data;

                setCreatorName(data.nickname);
            } catch (error) {
                console.error(error.message);
            }
        };

        if (creatorId) fetchCreator();
    }, [user, creatorId, isLoading]);

    const handleCardClick = () => {
        navigate(`/events/${id}`);
    }

    return (
        <div className="event-card" onClick={handleCardClick}>
            {isHost && <img className="hosting" src={imgHosting} width="66px" height="28px" alt="hosting" />}
            <div className="image-container">
                {imageUrl ? (
                    <img src={imageUrl} className="event-image" alt="event-image"/>
                ) : (
                    <div className="empty-image">
                        <p style={{letterSpacing: "-0.02em"}}>YOU ARE</p>
                        <p style={{letterSpacing: "0.05em"}}>INVITED</p>
                    </div>
                )
                }
            </div>
            <div className="title">{title}</div>
            <div className="info">
                <div className="date">
                    <img src={icCalendar} width="16px" height="16px"/>
                    <p>{startDate}</p>
                </div>
                <div className="location">
                    <img src={icLocation} width="16px" height="16px"/>
                    {location ? <p>{location}</p> : <p>추후공지</p>}
                </div>
                <div className="host">
                    <img src={icHost} width="16px" height="16px"/>
                    <p>{creatorName}</p>
                </div>
                <div className="count">
                    <img src={icPeople} width="16px" height="16px"/>
                    <p>{count}명 참여 중</p>
                </div>
            </div>
        </div>
    );
};

export default EventCard;