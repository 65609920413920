// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    /* brand */
    --brand_primary_01: #2255FF;
    --brand_primary_02: #2244DF;
    --brand_primary_03: #0027AA;

    /* text */
    --text_neutral_01: #333333;
    --text_neutral_02: #222222;
    --text_neutral_03: #9D9D9D;
    --text_neutral_04: #CECECE;
    --text_neutral_05: #E3E3E3;
    --text_neutral_white: #FFFFFF;

    --text_color_blue: #829AF9;
    --text_color_red: #FF6B6B;
    --text_color_yellow: #FFBF00;
    --text_color_lightBlue: #C8D3FB;

    /* base */
    --base_white: #FFFFFF;
    --base_02: #ECF1FF;
    --base_03: #EDEDED;

    /* border */
    --border_01: #E6E6E6;
    --border_02: #A3A3A3;

    /* shadow */
    --shadow_medium: 0px 2px 10px 5px rgba(0, 0, 0, 0.14);
}`, "",{"version":3,"sources":["webpack://./src/style/colors.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,2BAA2B;IAC3B,2BAA2B;IAC3B,2BAA2B;;IAE3B,SAAS;IACT,0BAA0B;IAC1B,0BAA0B;IAC1B,0BAA0B;IAC1B,0BAA0B;IAC1B,0BAA0B;IAC1B,6BAA6B;;IAE7B,0BAA0B;IAC1B,yBAAyB;IACzB,4BAA4B;IAC5B,+BAA+B;;IAE/B,SAAS;IACT,qBAAqB;IACrB,kBAAkB;IAClB,kBAAkB;;IAElB,WAAW;IACX,oBAAoB;IACpB,oBAAoB;;IAEpB,WAAW;IACX,qDAAqD;AACzD","sourcesContent":[":root {\n    /* brand */\n    --brand_primary_01: #2255FF;\n    --brand_primary_02: #2244DF;\n    --brand_primary_03: #0027AA;\n\n    /* text */\n    --text_neutral_01: #333333;\n    --text_neutral_02: #222222;\n    --text_neutral_03: #9D9D9D;\n    --text_neutral_04: #CECECE;\n    --text_neutral_05: #E3E3E3;\n    --text_neutral_white: #FFFFFF;\n\n    --text_color_blue: #829AF9;\n    --text_color_red: #FF6B6B;\n    --text_color_yellow: #FFBF00;\n    --text_color_lightBlue: #C8D3FB;\n\n    /* base */\n    --base_white: #FFFFFF;\n    --base_02: #ECF1FF;\n    --base_03: #EDEDED;\n\n    /* border */\n    --border_01: #E6E6E6;\n    --border_02: #A3A3A3;\n\n    /* shadow */\n    --shadow_medium: 0px 2px 10px 5px rgba(0, 0, 0, 0.14);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
