import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInstance";
import {Toast} from "../../style/StyledToastContainer.tsx";
import './Signup.css';
import {ERROR_MESSAGES} from "../../constants/errorMessages";

const EmailVerification = () => {
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [isCodeSent, setIsCodeSent] = useState(false);

    const navigate = useNavigate();

    const handleSendCode = async () => {
        try {
            // 이메일 중복 확인
            const checkResponse = await axiosInstance.post('/user/email', {email});
            if (checkResponse.data.exists) {
                Toast.error(ERROR_MESSAGES.AUTH.EMAIL_EXISTS);
                return;
            }

            // 인증코드 전송
            const response = await axiosInstance.post('', email);
            if (response.status === 200) {
                setIsCodeSent(true);
            }
        } catch (error) {
            console.error("Failed to send verificaation code", error);
            Toast.error("인증코드 전송에 실패했습니다.");
        }
    }

    const handleVerifyCode = async () => {
        try {
            // 인증코드 검증
            const response = await axiosInstance.post('', { email, verificationCode });
            if (response.status === 200) {
                navigate("/signup/complete");
            } else {
                console.error("Verification code is incorrect.");
                Toast.error("인증 번호가 일치하지 않습니다.");
            }
        } catch (error) {
            console.error("Verification failed: ", error);
        }
    };

    return (
        <div className="signup-container">
            <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@company.com"
                required
            />
            {isCodeSent &&
                <input
                    type="text"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    placeholder="인증 코드"
                    required
                />
            }
            {!isCodeSent ? (
                <button onClick={handleSendCode}>인증코드 요청</button>
            ) : (
                <button onClick={handleVerifyCode}>인증코드 확인</button>
            )}
        </div>
    );
};

export default EmailVerification;