import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './About.css';
import aboutImage01 from "../style/aboutPage/aboutImage01.png";
import aboutImage02 from "../style/aboutPage/aboutImage02.png";
import aboutImage03 from "../style/aboutPage/aboutImage03.png";
import service01image from "../style/aboutPage/service01Image.png";
import service02image from "../style/aboutPage/service02Image.png";
import service03imageLeft from "../style/aboutPage/service03ImageLeft.png";
import service03imageRight from "../style/aboutPage/service03ImageRight.png";
import * as ChannelService from '@channel.io/channel-web-sdk-loader';

const About = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/events/create");
    }

    const handleContactClick = () => {
        navigate("/contact");
    }

    useEffect(() => {
        const initializeChannelIO = async () => {
            try {
                await ChannelService.loadScript();

                ChannelService.boot({
                    "pluginKey": "67a83897-ce91-4e5c-bb92-cce6f564fc42",
                });
            } catch (error) {
                console.error('ChannelIO initialization failed:', error);
            }
        };

        initializeChannelIO();
    }, []);

    return (
        <div className="about-page-container">
            <section className="intro">
                <p className="about-small-font">소규모 네트워킹부터 B2B/B2C 행사까지</p>
                <p className="about-big-font">성공적인 이벤트 운영을 위한 올인원 플랫폼</p>
                <div className="button-container">
                    <button className="about-button hover-btn" onClick={handleClick} style={{width: "171px"}}>이벤트 만들기
                    </button>
                    <button className="about-button hover-btn" onClick={handleContactClick}>사용 문의</button>
                </div>
            </section>
            <section id="product" className="about">
                <p className="title">About</p>
                <p className="content-small" style={{marginBottom: "14px"}}>이벤트 운영의 복잡한 과정과 낮은 네트워킹 성과로 고민 중이신가요?</p>
                <p className="content-big">Wavely로 참가자 유치부터 네트워킹까지,</p>
                <p className="content-big">모든 과정을 간편하게 관리하고 성과를 극대화하세요</p>
                <div className="about-image-wrapper-1">
                    <img src={aboutImage01}/>
                    <img src={aboutImage02}/>
                    <img src={aboutImage03}/>
                </div>
                <div className="divider"/>
            </section>
            <section className="service">
                <>
                    <p className="title" style={{marginBottom: "58px"}}>Main Service</p>
                    <p className="content-big">이벤트 통합 관리와 강력한 네트워킹 솔루션으로</p>
                    <p className="content-big" style={{marginBottom: "43px"}}>더 나은 이벤트 성과를 실현해 보세요</p>
                </>
                <>
                    <p className="content-gray">전문가 고용이 필요 없이 손쉽게 이벤트를 주최하세요</p>
                    <div
                        style={{display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "33px"}}>
                        <p className="content-big">이벤트 운영에 필요한 절차들을&nbsp;</p>
                        <p className="content-big" style={{color: "#2255FF"}}>한곳에서 관리</p>
                        <p className="content-big">해 보세요</p>
                    </div>
                    <div style={{display: "flex", flexDirection: "column", marginBottom: "72px"}}>
                        <p className="content-small">아직도 이벤트 페이지를 만들고, 엑셀로 참여자 리스트를 관리하며, 구글 폼으로 RSVP와 참여자 성향을 파악하고
                            계신가요?</p>
                        <p className="content-small">이제 Wavely로 이벤트 정보 페이지부터 RSVP 관리, 프로필 등록, 네트워킹까지 모두 한 번에 처리하세요</p>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <img src={service01image} width="1180px" height="460px" style={{marginBottom: "80px"}}/>
                    </div>
                </>
                <>
                    <p className="content-gray">이벤트의 모든 정보를 한 곳에서 손쉽게 관리하고, 중요한 퀄리티에만 집중하세요</p>
                    <div
                        style={{display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "33px"}}>
                        <p className="content-big" style={{color: "#2255FF"}}>효율적인 이벤트 관리</p>
                        <p className="content-big">로 시간과 비용을 절약하세요</p>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <p className="content-small">참여자들의 특성과 RSVP 데이터를 오프라인 이벤트 기획에 활용할 인사이트로 전환하세요.</p>
                        <p className="content-small">커뮤니티 기능과 공지 발송 기능을 활용해 이벤트 정보의 원활한 교류를 지원하고,</p>
                        <p className="content-small">RSVP 현황을 파악해 미참여자들의 특징을 분석하고 리타겟팅하여 참여율을 높이세요.</p>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <img src={service02image} width="752px" height="588px"
                             style={{marginTop: "57px", marginBottom: "80px"}}/>
                    </div>
                </>
                <>
                    <p className="content-gray">참가자들이 네트워크를 확장하고, 기회를 이어가도록 지원하세요</p>
                    <div
                        style={{display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "33px"}}>
                        <p className="content-big">이벤트 전후로&nbsp;</p>
                        <p className="content-big" style={{color: "#2255FF"}}>지속적인 네트워킹</p>
                        <p className="content-big">을 제공하세요</p>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <p className="content-small">참가자들이 의미 있는 연결을 지속적으로 구출할 수 있도록, 효과적인 네트워킹 환경을 조성하세요.</p>
                        <p className="content-small">프로필 기반 커뮤니티와 소그룹 세션 기능을 통해 참가자들은</p>
                        <p className="content-small">커피챗으로 1:1 네트워킹을 간편하게 시작하고, 주제별 그룹으로 나뉘어 밋업 세션을 스케쥴링할 수 있습니다.</p>
                    </div>
                    <div className="about-image-wrapper-2">
                        <img src={service03imageLeft} width="442px" height="227px"/>
                        <img src={service03imageRight} width="498px" height="271px"/>
                    </div>
                </>
                <>
                    <p className="content-gray">데이터 인사이트를 통해 더 나은 비지니스 결정을 내리세요</p>
                    <div
                        style={{display: "flex", flexDirection: "row", justifyContent: "center", marginBottom: "32px"}}>
                        <p className="content-big">성과를 분석하고 공유하며, &nbsp;</p>
                        <p className="content-big" style={{color: "#2255FF"}}>미래를 위한 전략</p>
                        <p className="content-big">을 세워보세요</p>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <p className="content-small">이벤트 수익 창출 기회로 전환하여 스폰서와 영업팀의 ROI를 극대화하세요.</p>
                        <p className="content-small">RSVP, 세션 참석, 페이지 하이라이트, 네트워킹 성과 등 핵심 결과를 내부 및 외부 이해관계자와 쉽게
                            공유하세요.</p>
                    </div>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <img src={service02image} width="752px" height="582px"
                             style={{marginTop: "57px", marginBottom: "80px"}}/>
                    </div>
                </>
            </section>
            <section className="banner">
                <p className="banner-big-text">내 손으로 만드는 성공적인 이벤트</p>
                <p className="banner-small-text">Wavely로 간편하게 설계하고 성과를 경험해 보세요</p>
                <button className="banner-button" onClick={handleContactClick}>사용 신청하기</button>
            </section>
        </div>
    );
};

export default About;