import React, {useState, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../contexts/AuthContext";
import {storage} from "../firebase";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";
import axiosInstance from "../axiosInstance";
import {Toast} from "../style/StyledToastContainer.tsx";
import {ERROR_MESSAGES} from "../constants/errorMessages";
import './CreateEvent.css'
import {StyledCalendarWrapper, StyledCalendar, StyledToday} from "../style/styles.ts";
import 'react-calendar/dist/Calendar.css'
import moment from "moment/moment";
import 'moment/locale/ko';
import LoginModal from "../component/modal/LoginModal";
import SignupModal from "../component/modal/SignupModal";
import icCreateEventLarge from "../style/icon/png/icCreateEventLarge.png";
import icCalendar from "../style/icon/png/icCalendar.png";
import icTilde from "../style/icon/png/icTilde.png";
import icLocation from "../style/icon/png/icLocation.png";
import icMemo from "../style/icon/png/icMemo.png";
import icError from "../style/icon/png/icError.png";
import icNecessary from "../style/icon/png/icNecessary.png";
import {CalendarField, TextareaField, TextFieldSmall} from "../style/components";

moment.locale('ko');

const CreateEvent = () => {
    const { isLoggedIn, setIsLoggedIn } = useAuth();

    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState(false);

    const inputRef = useRef(null);

    const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(false);
    const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(moment(new Date()).format("M월 DD일 (dd)"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("M월 DD일 (dd)"));

    const startCalendarRef = useRef(null);
    const endCalendarRef = useRef(null);

    const [place, setPlace] = useState("");
    const [showHistory, setShowHistory] = useState(false);
    const [placeHistory, setPlaceHistory] = useState([]);
    const [placeError, setPlaceError] = useState(false);

    const placeRef = useRef(null);
    const historyRef = useRef(null);

    const [about, setAbout] = useState("");
    const [aboutError, setAboutError] = useState(false);

    const [image, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState("");

    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isSignupOpen, setIsSignupOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const savedHistory = localStorage.getItem("placeHistory");
        if (savedHistory) {
            setPlaceHistory(JSON.parse(savedHistory));
        }
    }, []);

    const formatDateToBackend = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${year}${month}${day} ${hours}:${minutes}:${seconds}`;
    };

    const handleCreateClick = async () => {
        try {
            if (titleError || placeError || aboutError) {
                return;
            }

            if (isLoggedIn) {
                const formattedStartDate = formatDateToBackend(selectedStartDate);
                const formattedEndDate = formatDateToBackend(selectedEndDate);

                const createdEvent = {
                    title: title,
                    description: about,
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    location: place,
                    imageUrl: imageUrl || null,
                };

                if (image && !imageUrl) {
                    console.log("이미지 업로드 미완료");
                    return;
                }

                // 이벤트 생성
                const response = await axiosInstance.post('/event', createdEvent);

                if (response.status === 200) {
                    // 히스토리에 장소 저장
                    if (!placeHistory.includes(place)) {
                        const updatedHistory = [...placeHistory, place];
                        setPlaceHistory(updatedHistory);
                        localStorage.setItem("placeHistory", JSON.stringify(updatedHistory));
                    }

                    const eventList = response.data.eventList;

                    if (eventList && eventList.length > 0 && eventList[0].event) {
                        const createdEventId = eventList[0].event.id;
                        navigate(`/events/${createdEventId}`);
                    }
                } else {
                    Toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
                }
            } else {
                setIsLoginOpen(true);
            }
        } catch (error) {
            Toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
            console.error(error);
        }
    }

    // 모달창으로 로그인 성공 시 로그인 여부 반영
    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
        setIsLoginOpen(false);
    }

    const handleImageUpload = async (file) => {
        const storageRef = ref(storage, `eventImages/${file.name}`);

        const snapshot = await uploadBytes(storageRef, file);

        const url = await getDownloadURL(snapshot.ref);

        return url;
    }

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                setImage(reader.result);

                try {
                    const url = await handleImageUpload(file);
                    setImageUrl(url);
                    Toast.success("이미지가 성공적으로 업데이트되었습니다!");
                } catch (error) {
                    console.error("이미지 업로드 실패: ", error);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    useEffect(() => {
        const handleTitleChange = () => {
            if (title.length < 2) setTitleError(true);
            else setTitleError(false);
        };

        handleTitleChange();
    }, [title]);

    useEffect(() => {
        const handleAboutChange = () => {
            if (about.length > 3000) setAboutError(true);
            else setAboutError(false);
        };

        handleAboutChange();
    }, [about]);

    const handleToggleStartCalendar = () => {
        setIsStartCalendarOpen(!isStartCalendarOpen);
        setIsEndCalendarOpen(false);
    }

    const handleToggleEndCalendar = () => {
        setIsEndCalendarOpen(!isEndCalendarOpen);
        setIsStartCalendarOpen(false);
    }

    const handleStartDateChange = (selectedDate) => {
        if (selectedDate.setHours(0, 0, 0, 0) < (new Date()).setHours(0, 0, 0, 0)) {
            Toast.error("선택한 시간이 허용된 가장 이른 시간보다 앞섭니다.");
        } else {
            setSelectedStartDate(selectedDate);
            setIsStartCalendarOpen(false);
            setStartDate(moment(selectedDate).format("M월 DD일 (dd)"));
            if (selectedDate > selectedEndDate) {
                setSelectedEndDate(selectedDate);
                setEndDate(moment(selectedDate).format("M월 DD일 (dd)"));
            }
        }
    }

    const handleEndDateChange = (selectedDate) => {
        if (selectedDate < selectedStartDate) {
            Toast.error("선택한 시간이 허용된 가장 이른 시간보다 앞섭니다.");
        } else {
            setSelectedEndDate(selectedDate);
            setIsEndCalendarOpen(false);
            setEndDate(moment(selectedDate).format("M월 DD일 (dd)"));
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if ((placeRef.current && placeRef.current.contains(event.target))
                || (historyRef.current && historyRef.current.contains(event.target))
                || (startCalendarRef.current && startCalendarRef.current.contains(event.target))
                || (endCalendarRef.current && endCalendarRef.current.contains(event.target))) {
                return;
            }

            if (place.length === 1 || place.length > 100) {
                setPlaceError(true);
            } else {
                setPlaceError(false);
            }

            setShowHistory(false);
            setIsStartCalendarOpen(false);
            setIsEndCalendarOpen(false);
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handlePlaceFocus = () => {
        setShowHistory(true);
    };

    const handleSelectHistory = (item) => {
        setPlace(item);
        setShowHistory(false);
        setPlaceError(false);
        console.log("history selected")
    };

    const adjustHeight = () => {
        const input = inputRef.current;
        if (input) {
            input.style.height = 'auto';
            input.style.height = `${input.scrollHeight}px`;
        }
    };

    useEffect(() => {
        adjustHeight();
    }, [title]);

    return (
        <>
            {isLoginOpen &&
                <LoginModal
                    isOpen={isLoginOpen}
                    setIsOpen={setIsLoginOpen}
                    onLoginSuccess={() => handleLoginSuccess()}
                    openSignup={() => {
                        setIsLoginOpen(false);
                        setIsSignupOpen(true);
                    }}
                />}
            {isSignupOpen &&
                <SignupModal
                    isOpen={isSignupOpen}
                    setIsOpen={setIsSignupOpen}
                    openLogin={() => {
                        setIsSignupOpen(false);
                        setIsLoginOpen(true);
                    }}
                />}
            <div className="event-detail-wrapper">
                <div className="event-detail">
                    <div className="image-container">
                        <div className={`image-placeholder ${image ? "has-image" : ""}`}>
                            {image ? (
                                <img src={image} className="event-image"  alt="event-image"/>
                            ) : (
                                <>
                                    <img src={icCreateEventLarge} className="input-image-icon" width="46px" height="46px" alt="icCreateEventLarge" />
                                    <p>이벤트를 돋보이게 할</p>
                                    <p>커버 이미지를 등록하세요</p>
                                </>
                            )}
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                className="image-input"
                            />
                        </div>
                    </div>
                    <div className="content-container">
                        <div className="title-container">
                            <textarea
                                rows={1}
                                ref={inputRef}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                    adjustHeight();
                                }}
                                placeholder="이벤트 이름"
                            />
                            {title.length === 0 && <img className="ic-necessary" src={icNecessary} width="8px" height="8px" alt="icNecessary"/>}
                            {titleError && title.length !== 0  && (
                                <div className="alert" style={{ margin: "0" }}>
                                    <img src={icError} width="16px" height="16px"/>
                                    <p className="alert-text">이벤트명은 최소 2글자 이상 입력해 주세요</p>
                                </div>
                            )}
                        </div>
                        <div className="contents">
                            <div className="date-container">
                                <div className="label">
                                <img src={icCalendar} alt="icCalendar"/>
                                    <p>이벤트 날짜</p>
                                </div>
                                <div className="calendar-container">
                                    <div onClick={handleToggleStartCalendar}>
                                        <CalendarField
                                            type="시작"
                                            value={startDate}
                                        />
                                    </div>
                                    <img src={icTilde} width="9px" height="6px" style={{margin: "0 10.5px"}}/>
                                    <div onClick={handleToggleEndCalendar}>
                                        <CalendarField
                                            type="종료"
                                            value={endDate}
                                        />
                                    </div>
                                </div>
                                {isStartCalendarOpen && <StyledCalendarWrapper className="calendar-wrapper" ref={startCalendarRef}>
                                    <StyledCalendar
                                        onChange={handleStartDateChange}
                                        value={selectedStartDate}
                                        formatDay={(locale, date) => moment(date).format("DD")}
                                        tileContent={({ date, view }) => {
                                            const today = new Date();

                                            if (
                                                view === "month" &&
                                                date.getFullYear() === today.getFullYear() &&
                                                date.getMonth() === today.getMonth() &&
                                                date.getDate() === today.getDate() &&
                                                date.getDate() !== selectedStartDate.getDate()
                                            ) {
                                                return (
                                                    <div style={{position: "relative", width: "100%", height: "100%"}}>
                                                        <StyledToday key={today}/>
                                                    </div>

                                                );
                                            }
                                        }}
                                    />
                                </StyledCalendarWrapper>}
                                {isEndCalendarOpen && <StyledCalendarWrapper className="calendar-wrapper" ref={endCalendarRef}>
                                    <StyledCalendar
                                        onChange={handleEndDateChange}
                                        value={selectedEndDate}
                                        formatDay={(locale, date) => moment(date).format("DD")}
                                        tileContent={({ date, view }) => {
                                            const today = new Date();

                                            if (
                                                view === "month" &&
                                                date.getFullYear() === today.getFullYear() &&
                                                date.getMonth() === today.getMonth() &&
                                                date.getDate() === today.getDate() &&
                                                date.getDate() !== selectedEndDate.getDate()
                                            ) {
                                                return (
                                                    <div style={{position: "relative", width: "100%", height: "100%"}}>
                                                        <StyledToday key={today}/>
                                                    </div>

                                                );
                                            }
                                        }}
                                    />
                                </StyledCalendarWrapper>}
                            </div>
                            <div className="place-container">
                                <div className="label">
                                    <img src={icLocation} alt="icLocation"/>
                                    <p>이벤트 위치</p>
                                </div>
                                <div onClick={handlePlaceFocus} ref={placeRef}>
                                    <TextFieldSmall
                                        placeholder="오프라인 위치 또는 가상 링크"
                                        value={place}
                                        onChange={(e) => setPlace(e.target.value)}
                                        isWide={true}
                                    />
                                </div>
                                {showHistory && (
                                    <ul className="place-history" ref={historyRef}>
                                        {placeHistory.map((item, index) => (
                                            <li key={index} onClick={() => handleSelectHistory(item)}>
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <div className="about-container">
                                <div className="label">
                                    <img src={icMemo} alt="icMemo"/>
                                    <p>설명</p>
                                </div>
                                <TextareaField
                                    value={about}
                                    onChange={(e) => setAbout(e.target.value)}
                                    placeholder="이벤트는 무엇에 관한 것인가요?"
                                />
                                {aboutError && (
                                    <div className="alert" style={{ marginTop: "6px" }}>
                                        <img src={icError} width="16px" height="16px"/>
                                        <p className="alert-text">설명은 최대 3000글자까지 입력 가능합니다</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="button-container">
                            <button className="login-button hover-btn" onClick={handleCreateClick}>이벤트 만들기</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateEvent;