import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../axiosInstance";
import { useAuth } from "../contexts/AuthContext";
import './ProfileCard.css'
import {Toast} from "../style/StyledToastContainer.tsx";
import {ERROR_MESSAGES} from "../constants/errorMessages";
import ProfileEditModal from "./modal/ProfileEditModal";
import icLinkedin from "../style/icon/png/icLinkedin.png";
import icX from "../style/icon/png/icX.png";
import icInstagram from "../style/icon/png/icInstagram.png";
import icFacebook from "../style/icon/png/icFacebook.png";
import icLink from "../style/icon/png/icLink.png";
import imgHosting from "../style/icon/png/imgHosting.png";
import icClose from "../style/icon/png/icClose.png";

const ProfileCard = ({profileUserId, name, company, about, profileImage, contact, isModal = false, isHost = false, onClose} ) => {
    const [message, setMessage] = useState("");
    const [isModalOpen, setIsModalOpen]= useState(false);
    const [status, setStatus] = useState("");
    const { id } = useParams();
    const { user, isLoading } = useAuth();

    const [showEditButton, setShowEditButton] = useState(false);

    const [linkType, setLinkType] = useState("");
    const [formattedContact, setFormattedContact] = useState("");

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    // const fetchEvent = async () => {
    //     try {
    //         // 이벤트 정보 가져오기
    //         const response = await axiosInstance.get(`/event/${id}`);
    //         const data = response.data;
    //
    //         if (isLoggedIn) {
    //             if (data.attendUserIds.includes(user.userId)) setStatus("join");
    //             else if (data.unattendUserIds.includes(user.userId)) setStatus("not join");
    //             else if (creatorId === user.userId) setStatus("host");
    //         }
    //     } catch (error) {
    //         Toast.error(ERROR_MESSAGES.AUTH.UNAUTHORIZED);
    //         console.error(error);
    //     }
    // };
    //
    // useEffect(() => {
    //     fetchEvent();
    // }, [id, user.id]);
    //
    // const handleJoinClick = async () => {
    //     try {
    //         // 이벤트 참여
    //         const response = await axiosInstance.post(`/event/${id}/attend`, user.id);
    //
    //         if (response.status === 200 || response.status === 201) {
    //             await fetchEvent();
    //         } else {
    //             Toast.error(ERROR_MESSAGES.AUTH.UNAUTHORIZED);
    //         }
    //     } catch (error) {
    //         Toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
    //         console.error(error);
    //     }
    // };
    //
    // const handleCoffeeChat = () => {
    //     setIsModalOpen(prev => !prev);
    // }
    //
    // const handleSendMessage = async () => {
    //     try {
    //         if (message.trim() === "") return;
    //
    //         // 커피챗 요청 보내기
    //         const response = axiosInstance.post('', user.id, {profileUserId}, message);
    //
    //         if (response.status === 200 || response.status === 201) {
    //             setMessage("");
    //         } else {
    //             console.error("Failed to send coffee chat");
    //         }
    //     } catch (error) {
    //         console.error("Error sending coffee chat: ", error);
    //     }
    // }

    useEffect(() => {
        if (contact === null) return;

        const getLinkType = (url) => {
            try {
                if (url.includes("linkedin.com")) {
                    return "LinkedIn";
                } else if (url.includes("instagram.com")) {
                    return "Instagram";
                } else if (url.includes("facebook.com")) {
                    return "Facebook";
                } else if (url.includes("twitter.com") || url.includes("X")) {
                    return "X";
                } else {
                    return "Other";
                }
            } catch (error) {
                console.error("Invalid URL:", error);
                return "Invalid";
            }
        };

        const type = getLinkType(contact);
        setLinkType(type);

        const newContact = contact.startsWith("http") ? contact : `https://${contact}`;
        setFormattedContact(newContact);
    }, [contact]);

    const iconSrc = () => {
        switch (linkType) {
            case "LinkedIn":
                return icLinkedin;
            case "Instagram":
                return icInstagram;
            case "Facebook":
                return icFacebook;
            case "X":
                return icX;
            default:
                return icLink;
        }
    }

    useEffect(() => {
        if (!isLoading) {
            (user && (user.userId == profileUserId)) ? setShowEditButton(true) : setShowEditButton(false);
        }
    }, [user?.userId, profileUserId, isLoading])

    const handleEdit = () => {
        setIsEditModalOpen(true);
    }

    const coffeeChatClick = () => {
        Toast.success("커피챗 요청을 전송하였습니다")
    }

    return (
        <>
            {isEditModalOpen && (
                <ProfileEditModal userId={profileUserId} onClose={() => setIsEditModalOpen(false)} />
            )}
            <div className={`profile-card ${isModal ? 'modal-content' : ''}`}>
                {isModal &&
                    <img className="close-btn" onClick={onClose} src={icClose} width="18px" height="18px"/>}
                {isHost && <img className="hosting" src={imgHosting} width="66px" height="28px"/>}
                <div className="content-container">
                    <div style={{ display: "flex" }}>
                        {profileImage ? (
                            <img src={profileImage} className="profile-image"/>
                        ) : (
                            <div className="empty-profile-image">{name?.slice(0, 1)}</div>
                        )}
                        {contact !== null &&
                            (<a className="link" href={formattedContact} target="_blank" rel="noopener noreferrer">
                                <img src={iconSrc()} alt={`${linkType} Icon`} />
                            </a>)}
                        <div style={{ display: "flex", flexDirection: "column", marginTop: "13px" }}>
                            <p className="company">{company}</p>
                            <p className="name">{name}</p>
                        </div>
                    </div>
                    <div>
                        <p className="about">{about}</p>
                    </div>
                </div>
                <div className="button-container">
                    {(showEditButton) ? (<button className="hover-btn-white" onClick={handleEdit}>수정하기</button>)
                        : (<button className="hover-btn-white" onClick={coffeeChatClick}>커피챗 요청</button>)}
                </div>
                {/*{isModalOpen && (*/}
                {/*    <div>*/}
                {/*        <div className="coffee-chat-overlay" onClick={handleCoffeeChat} />*/}
                {/*        <div className="coffee-chat">*/}
                {/*            {(status === "join") ? (<>*/}
                {/*                    <p>{name}님에게 커피챗 요청 보내기</p>*/}
                {/*                    <input className="coffee-chat-input"*/}
                {/*                           type="text"*/}
                {/*                           value={message}*/}
                {/*                           onChange={(e) => setMessage(e.target.value)}*/}
                {/*                    />*/}
                {/*                    <button className="coffee-chat-send" onClick={handleSendMessage}>커피챗 요청</button>*/}
                {/*                </>) : (*/}
                {/*                    <>*/}
                {/*                        <p>커피챗 요청이 불가합니다</p>*/}
                {/*                        <p className="detail">참여 상태를 변경해 주세요</p>*/}
                {/*                        <div className="not-join">미참여</div>*/}
                {/*                        <button className="join-btn" onClick={handleJoinClick}>참여로 변경</button>*/}
                {/*                    </>*/}
                {/*            )*/}
                {/*        }*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}
            </div>
        </>
    );
};

export default ProfileCard;