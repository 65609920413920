import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Participants from "../component/Participants";
import "./ProfileBoard.css";
import axiosInstance from "../axiosInstance";
import ProfileEditModal from "../component/modal/ProfileEditModal";
import {useAuth} from "../contexts/AuthContext";
import StatusChangeModal from "../component/modal/StatusChangeModal";
import {Toast} from "../style/StyledToastContainer.tsx";
import {ERROR_MESSAGES} from "../constants/errorMessages";
import icAttending from "../style/icon/png/icAttending.png";
import LoginModal from "../component/modal/LoginModal";
import SignupModal from "../component/modal/SignupModal";
import icClose from "../style/icon/png/icClose.png";

const ProfileBoard = () => {
    const { id } = useParams();
    const { isLoggedIn, setIsLoggedIn, user, isLoading } = useAuth();

    const [isProfileEditOpen, setIsProfileEditOpen] = useState(false);
    const [isJoinOpen, setIsJoinOpen] = useState(false);
    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);

    const navigate = useNavigate();

    const fetchStatus = async () => {
        if (!isLoggedIn || !user || !user.userId) return "";

        try {
            console.log("Logged-in user: ", user.userId);

            // 이벤트 정보 가져오기
            const response = await axiosInstance.get(`/event/${id}`);
            const data = await response.data.eventList[0];

            // 참여/미참여 확인
            if (data.event.creatorId == user.userId) return "host";
            else if (data.attendUserIds.includes(Number(user.userId))) return "join";
            else if (data.unattendUserIds.includes(Number(user.userId))) return "not join";
            return "";
        } catch (error) {
            console.error(error.message);
            return "";
        }
    };

    const fetchProfile = async () => {
        try {
            const response = await axiosInstance.get(`/user/${user.userId}/profile`);
            const {nickname, company, introduction} = response.data;

            if (!nickname || !company || !introduction) {
                setIsProfileEditOpen(true);
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    const handleProfileEditClose = () => {
        setIsProfileEditOpen(false);
    };

    const loadStatusAndProfile = async () => {
        const fetchedStatus = await fetchStatus();
        console.log("attend status: ", fetchedStatus);

        if (fetchedStatus === "join" || fetchedStatus === "host") {
            await fetchProfile();
            setIsJoinOpen(false);
        } else {
            setIsJoinOpen(true);
        }
    };

    useEffect(() => {
        if (!isLoading && isLoggedIn && user && user.userId) {
            loadStatusAndProfile();
        }
    }, [isLoggedIn, user, isLoading]);

    const handleJoinClick = async () => {
        setIsStatusModalOpen(true);

        await loadStatusAndProfile();

        const updatedStatus = await fetchStatus();
        if (updatedStatus === "join") {
            setIsJoinOpen(false); // 상태를 join으로 바꾸면 false로 설정
        }
    };

    const handleStatusChange = async () => {
        try {
            let response;
            response = await axiosInstance.post(`/event/${id}/attend`);

            if (response.status === 200) {
                loadStatusAndProfile(); // 상태를 업데이트하여 다시 가져옴
            } else {
                Toast.error(ERROR_MESSAGES.AUTH.UNAUTHORIZED);
            }
        } catch (error) {
            Toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
            console.error(error);
        } finally {
            setIsStatusModalOpen(false); // 모달 닫기
        }
    }

    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
        setIsLoginModalOpen(false);
    }

    return (
        <>
            {isProfileEditOpen && (
                <ProfileEditModal
                    userId={user.userId}
                    onClose={handleProfileEditClose}
                />
            )}
            {isJoinOpen && (
                <>
                    <div className="modal-overlay white" style={{top: "144px"}}></div>
                    <div className="join-message">
                        <p className="message-big">커뮤니티 참여가 불가합니다</p>
                        <p className="message-small">이벤트 참여자만 접근 가능합니다</p>
                        <button className="hover-btn" onClick={handleJoinClick}>
                            <img src={icAttending} width="19px" height="19px" />
                            참여로 변경</button>
                    </div>
                </>
            )}
            {isLoginModalOpen &&
                <LoginModal
                    isOpen={isLoginModalOpen}
                    setIsOpen={setIsLoginModalOpen}
                    onLoginSuccess={() => handleLoginSuccess()}
                    openSignup={() => {
                        setIsLoginModalOpen(false);
                        setIsSignupModalOpen(true);
                    }}
                    isRSVP={true}
                />}
            {isSignupModalOpen &&
                <SignupModal
                    isOpen={isSignupModalOpen}
                    setIsOpen={setIsSignupModalOpen}
                    openLogin={() => {
                        setIsSignupModalOpen(false);
                        setIsLoginModalOpen(true);
                    }}
                />}
            {isStatusModalOpen && (
                <StatusChangeModal
                    isOpen={isStatusModalOpen}
                    onClose={() => setIsStatusModalOpen(false)}
                    action={"join"}
                    onConfirm={handleStatusChange}
                />
            )}
            {(!isLoggedIn) && <div className="feed-overlay">
                <p className="caution">로그인 시 확인할 수 있습니다.</p>
                <button className="web-header-btn hover-btn" onClick={() => setIsLoginModalOpen(true)}>로그인</button>
            </div>}
            <div className="profile-board">
                <p className="back" onClick={() => navigate(`/events/${id}`)}>&lt; 돌아가기</p>
                <p className="title">참여자</p>
                <div className="participants-container">
                    <Participants
                        id={id}
                    />
                </div>
            </div>
        </>
    );
};

export default ProfileBoard;
