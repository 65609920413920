import "./StatusChangeModal.css";

const StatusChangeModal = ({ isOpen, onClose, action, onConfirm }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-container">
            <div className="modal-overlay" onClick={onClose}></div>
            <div className="modal-content">
                <p className="modal-guide">{action === "join" ? "정말로 참여하시겠습니까?" : "정말로 미참여하시겠습니까?"}</p>
                <div style={{ marginTop: "30px", gap: "18px", display: "flex", flexDirection: "row" }}>
                    <button onClick={onConfirm} className={action}>{action === "join" ? "참여" : "미참여"}</button>
                    <button onClick={onClose} className="cancel hover-btn-gray">취소</button>
                </div>
            </div>
        </div>
    );
};

export default StatusChangeModal;