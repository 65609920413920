import React from "react";
import { useLocation } from "react-router-dom";
import "./Footer.css";
import "../style/colors.css";

const Footer = () => {
    const location = useLocation();

    // 소개 페이지가 아니면 푸터 숨김
    if (location.pathname !== '/') {
        return null;
    }

    return (
        <div className="footer">
            <div className="about">
                <p className="company">(주) 우디고</p>
                <p>대표: 김민수 | 주소: 서울시 관악구 낙성대로 02 | 사업자 등록번호: 222-86-02558</p>
            </div>
            <div className="detail">
                <a className="terms hover-text"
                   href="https://woodigo.notion.site/Wavely-ef038315c15e49079280089b23308362" target="_blank"
                   rel="noopener noreferrer">이용 약관</a>
                <a className="terms hover-text" href="https://woodigo.notion.site/57f13de24bc4434094d609da8be5e38b"
                   target="_blank" rel="noopener noreferrer">개인정보 처리방침</a>
            </div>
        </div>
    );
};

export default Footer;