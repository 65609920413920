import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { differenceInMinutes, differenceInHours, differenceInDays, differenceInMonths, differenceInYears, format } from 'date-fns';
import axiosInstance from "../axiosInstance";
import "./Feed.css";
import {Toast} from "../style/StyledToastContainer.tsx";
import {ERROR_MESSAGES} from "../constants/errorMessages";
import StatusChangeModal from "./modal/StatusChangeModal";
import {PostInputField} from "../style/components";

const Feed = ({ id }) => {
    const { isLoggedIn, user } = useAuth();
    const [profileImageUrl, setProfileImageUrl] = useState("");
    const [nickname, setNickname] = useState("");
    const [feeds, setFeeds] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

    useEffect(() => {
        const fetchProfileImageUrl = async () => {
            if (!user || !user.userId) return;

            try {
                const response = await axiosInstance.get(`/user/${user.userId}/profile`);
                setProfileImageUrl(response.data.profileImageUrl);
                setNickname(response.data.nickname);
            } catch (error) {
                console.error(error.message);
            }
        };

        fetchProfileImageUrl();
    }, [user])

    const fetchFeeds = async () => {
        try {
            // 피드 불러오기
            const response = await axiosInstance.get(`/event/${id}/feed`);
            const feedList = response.data.feedList;

            const feedsWithUserProfiles = await Promise.all(feedList.map(async (feed) => {
                const userResponse = await axiosInstance.get(`/user/${feed.creatorId}/profile`);
                return {
                    ...feed,
                    user: {
                        name: userResponse.data.nickname,
                        profileImage: userResponse.data.profileImageUrl
                    }
                };
            }));

            setFeeds(feedsWithUserProfiles);
        } catch (error) {
            console.error("Error fetching feeds: ", error);
        }
    };

    const fetchStatus = async () => {
        try {
            console.log("Logged-in user: ", user.userId);

            // 이벤트 정보 가져오기
            const response = await axiosInstance.get(`/event/${id}`);
            const data = await response.data.eventList[0];

            // 참여/미참여 확인
            if (isLoggedIn && user && user.userId) {
                if (data.event.creatorId == user.userId) return "host";
                else if (data.attendUserIds.includes(Number(user.userId))) return "join";
                else if (data.unattendUserIds.includes(Number(user.userId))) return "not join";
            }
            return "";
        } catch (error) {
            console.error(error.message);
            return "";
        }
    };

    const handleStatusChange = async () => {
        try {
            let response;
            response = await axiosInstance.post(`/event/${id}/attend`);

            if (response.status === 200) {
                window.location.reload(); // 새로고침
            } else {
                Toast.error(ERROR_MESSAGES.AUTH.UNAUTHORIZED);
            }
        } catch (error) {
            Toast.error(ERROR_MESSAGES.GENERAL.UNKNOWN_ERROR);
            console.error(error);
        } finally {
            setIsStatusModalOpen(false); // 모달 닫기
        }
    }

    useEffect(() => {
        fetchFeeds();
    }, [id]);

    const getTimeText = (createdAt) => {
        const now = new Date();
        const createdDate = new Date(createdAt);

        const minutesDiff = differenceInMinutes(now, createdDate);
        if (minutesDiff < 1) {
            return "방금 전";
        }
        if (minutesDiff < 60) {
            return `${minutesDiff}분 전`;
        }

        const hoursDiff = differenceInHours(now, createdDate);
        if (hoursDiff < 24) {
            return `${hoursDiff}시간 전`;
        }

        const daysDiff = differenceInDays(now, createdDate);
        if (daysDiff < 30) {
            return `${daysDiff}일 전`;
        }

        const monthsDiff = differenceInMonths(now, createdDate);
        if (monthsDiff < 12) {
            return `${format(createdDate, 'MM월 dd일')}`;
        }

        const yearsDiff = differenceInYears(now, createdDate);
        return `${yearsDiff}년 전`;
    };

    const handlePost = async () => {
        const fetchedStatus = await fetchStatus();

        if (fetchedStatus === "join" || fetchedStatus === "host") {
            if (inputValue.trim() === "") return;

            try {
                // 포스트 전송
                const response = await axiosInstance.post(`/event/${id}/feed`, {eventId: id, content: inputValue});

                if (response.status === 200) {
                    await fetchFeeds();
                    setInputValue("");
                } else {
                    console.error("Failed to post feed");
                }
            } catch (error) {
                console.error("Error posting feed: ", error);
            }
        } else { // 미참여 유저
            setIsStatusModalOpen(true);
        }
    };

    return (
        <>
            {isStatusModalOpen && (
                <StatusChangeModal
                    isOpen={isStatusModalOpen}
                    onClose={() => setIsStatusModalOpen(false)}
                    action={"join"}
                    onConfirm={handleStatusChange}
                />
            )}
            <div className="feeds-container">
                <div className="feed">
                    {feeds.map((feed, index) => {
                        const profileImage = feed.user.profileImage;
                        const name = feed.user.name;

                        return (
                            <div className="chat" key={index}>
                                {profileImage ? (
                                    <img src={profileImage} className="chat-profile" alt="Profile"/>
                                ) : (
                                    <div className="chat-empty-profile">
                                        {name?.slice(0, 1)}
                                    </div>
                                )}
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                                        <p className="user-name">{feed.user.name}</p>
                                        <p className="chat-time">{getTimeText(feed.createdAt)}</p>
                                    </div>
                                    <p className="chat-content">{feed.content}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <PostInputField
                    profileImageUrl={profileImageUrl}
                    nickname={nickname}
                    inputValue={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    onClick={handlePost}
                />
            </div>
        </>
    );
};

export default Feed;
